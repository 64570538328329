import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import NotFoundView from '../errors/NotFoundView';
import {
  PAGE_SIZE_LIST,
  STATUS_API,
  messageToastType_const,
} from 'src/app/constant/config';
import ToastMessage from 'src/app/components/ToastMessage';
import DialogConfirm from 'src/app/components/DialogConfirm';
import Page from 'src/app/components/Page';
import {
  closeDialogConfirm,
  showDialogConfirm,
  showToast
} from 'src/features/uiSlice';
import Results from './Results';
import Toolbar from './ToolBar';
import { MESSAGE } from 'src/app/constant/message';
import { resetChange } from 'src/features/vehicleSlice';
import { getListDevice, deleteDevice } from 'src/features/deviceSlice';
import { roles } from 'src/app/constant/roles';
import { useNavigate, useLocation } from 'react-router-dom';
import { _convertObjectToQuery } from 'src/app/utils/apiService';
import ActiveDeviceModal from './active';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DeviceListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  // get data api

  const totalDevice = useSelector(state => state.deviceSlice.totalDevice);
  const isLoading = useSelector(state => state.deviceSlice.isLoading);

  const statusCreate = useSelector(state => state.deviceSlice.statusCreate);
  const statusDelete = useSelector(state => state.deviceSlice.statusDelete);
  const statusUpdate = useSelector(state => state.deviceSlice.statusUpdate);

  const statusGetSellers = useSelector(
    state => state.userSlice.statusGetSellers
  );
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';

  const statusActive = useSelector(state => state.deviceSlice.statusActive);
  const statusRecall = useSelector(state => state.deviceSlice.statusRecall);
  const statusTransfer = useSelector(state => state.deviceSlice.statusTransfer);
  const statusImport = useSelector(state => state.deviceSlice.statusImport);
  const err = useSelector(state => state.deviceSlice.err);

  // set state
  // dvt
  const [openModalActiveDevice, setOpenModalActiveDevice] = useState(false);
  const resultImport = useSelector(state => state.deviceSlice.resultImport);

  const [dataSelected, setDataSelected] = useState({
    serial: '',
    hardware_version: ''
  });

  useEffect(() => {
    if (!localStorage.getItem('access-token')) return;
    dispatch(getListDevice(params));
  }, []);

  const [params, setParams] = useState({
    page: query.get('page') || 1,
    page_size: query.get('page_size') || PAGE_SIZE_LIST,
    is_pagination: true
  });

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusCreate === STATUS_API.ERROR ||
      statusDelete === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.ERROR ||
      statusUpdate === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.ERROR ||
      statusActive === STATUS_API.SUCCESS ||
      statusActive === STATUS_API.ERROR ||
      statusRecall === STATUS_API.SUCCESS ||
      statusRecall === STATUS_API.ERROR ||
      statusTransfer === STATUS_API.SUCCESS ||
      statusTransfer === STATUS_API.ERROR
    ) {
      dispatch(showToast());
    }

    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS ||
      statusActive === STATUS_API.SUCCESS ||
      statusRecall === STATUS_API.SUCCESS ||
      statusTransfer === STATUS_API.SUCCESS
    ) {
      dispatch(getListDevice(params));
    }
  }, [statusCreate, statusDelete, statusUpdate, statusActive, statusRecall, statusTransfer]);

  useEffect(() => {
    if (resultImport !== null) {
      dispatch(showToast());
      dispatch(getListDevice(params));
    }
  }, [resultImport]);


  const getListDeviceWithParams = data => {
    const paramValue = Object.assign({}, params, data);
    setParams(paramValue);
    dispatch(getListDevice(paramValue));
  };

  const clearSearch = () => {
    const paramValue = {
      page: 1,
      page_size: PAGE_SIZE_LIST
    };
  };

  const activeNewDevice = device => {
    setDataSelected({
      serial: device?.serial,
      hardware_version: device?.hardware_version
    });
  };



  const closeModalActiveDevice = () => {
    setOpenModalActiveDevice(false);
    dispatch(resetChange());
  };

  // isAdmin ?
  return (
    <Page className={classes.root}>
      {statusGetSellers !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          type={
            statusGetSellers === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
          message={
            statusGetSellers === STATUS_API.SUCCESS
              ? 'Get seller thành công'
              : err
          }
        />
      )}

      {statusCreate !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          type={
            statusCreate === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
          message={
            statusCreate === STATUS_API.SUCCESS
              ? MESSAGE.CREATE_DEVICE_SUCCESS
              : err
          }
        />
      )}

      {statusUpdate !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          type={
            statusUpdate === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
          message={
            statusUpdate === STATUS_API.SUCCESS
              ? MESSAGE.UPDATE_DEVICE_SUCCESS
              : err
          }
        />
      )}

      {statusDelete !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          type={
            statusDelete === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
          message={
            statusDelete === STATUS_API.SUCCESS
              ? MESSAGE.DELETE_DEVICE_SUCCESS
              : err
          }
        />
      )}

      {statusActive !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          message={
            statusActive === STATUS_API.SUCCESS
              ? MESSAGE.ACTIVE_DEVICE_SUCCESS
              : err
          }
          type={
            statusActive === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
        />
      )}

      {statusRecall !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          message={
            statusRecall === STATUS_API.SUCCESS
              ? MESSAGE.RECALL_DEVICE_SUCCESS
              : err
          }
          type={
            statusRecall === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
        />
      )}

      {statusTransfer !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          message={
            statusTransfer === STATUS_API.SUCCESS
              ? MESSAGE.TRANSFER_DEVICE_SUCCESS
              : err
          }
          type={
            statusTransfer === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
        />
      )}

      {resultImport !== null && (
        <ToastMessage
          callBack={() => dispatch(resetChange())}
          message={
            statusImport === STATUS_API.SUCCESS
              ? `Import thành công ${resultImport?.insert_success} và có ${resultImport?.duplicate} bị trùng serial`
              : err
          }
          type={
            statusImport === STATUS_API.SUCCESS
              ? messageToastType_const.success
              : messageToastType_const.error
          }
        />
      )}

      <div className="container-fluid max-width-1600 mb-5 mt-4">
        <Toolbar
          isLoading={isLoading}
          searchRef={getListDeviceWithParams}
          clearSearchRef={clearSearch}
          setOpenModalActiveDevice={setOpenModalActiveDevice}
        />
        <Box mt={5}>
          <Results
            totalDevice={totalDevice}
            isLoading={isLoading}
            getListDeviceRef={getListDeviceWithParams}
            activeNewDevice={activeNewDevice}
          />
        </Box>
      </div>

      <ActiveDeviceModal
        open={openModalActiveDevice}
        closeRef={closeModalActiveDevice}
        dataSelected={dataSelected}
      />
    </Page>
  );

  //: (<Page className={classes.root} title="Not Found">
  //   <Container maxWidth={false}>
  //     <NotFoundView />
  //   </Container>
  // </Page>);
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    //minHeight: 'calc(100vh - 100px)',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export default DeviceListView;
