import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  Chip,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress,
  withStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { green } from '@material-ui/core/colors';
import { useNavigate } from 'react-router-dom';
import {
  DEVICE_STATUS,
  messageToastType_const,
  STATUS_API
} from 'src/app/constant/config';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { getListAgencies } from 'src/features/agencySlice';
import {
  updateStaff,
  getListStaff,
  resetChange
} from 'src/features/staffSlice';
import { licensePlate } from 'src/features/reportBgtQC31Slice';
import { vehicleType } from 'src/app/constant/vehicleType';
import {
  activeDevice,
  getUsernameByPhoneNumber,
  resetSearchUsernameByPhone
} from 'src/features/deviceSlice';

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'red' // (default alpha is 0.38)
    }
  }
})(TextField);

const DeviceActiveForm = ({ device, closeRef, dataSelected }) => {
  console.log('check modal dataSelected', dataSelected);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector(state => state.deviceSlice.errors);
  const statusActive = useSelector(state => state.deviceSlice.statusActive);
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const username = useSelector(state => state.deviceSlice.username);
  const [listAgenciesSelect, setListAgenciesSelect] = useState([]);

  const dataInit = {
    phone: '',
    full_name: '',
    serial: dataSelected?.serial || '',
    title_device_type: '',
    vehicle_license_plate: '',
    version: dataSelected?.hardware_version || '',
    name_of_user_for_gps: '',
    tonnage: '',
    slots: '',
    service_package: ''
  };
  const [initValue, setInitValue] = useState(dataInit);
  const [deviceTypeDetail, setDeviceTypeDetail] = useState({});
  const [selectedTonnage, setSelectedTonnage] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState('');

  // console.log('deviceTypeDetail ===>', deviceTypeDetail);

  const handleSubmit = data => {
    const listAgenciesID = listAgenciesSelect.map(item => item.id);

    if (data && data.serial) {
      const dataActive = {
        phone: selectedPhone,
        full_name: username ? username : '',
        serial: data?.serial,
        version: data?.version,
        vehicle_license_plate: deviceTypeDetail?.id !== 1 ? data.vehicle_license_plate : null, // do tên người với biển số xe đang dùng chung 1 Textfield
        title_device_type: deviceTypeDetail?.name ? deviceTypeDetail.name : '',
        slots: data?.slots ? Number(data?.slots) : null,
        tonnage: data?.tonnage ? data?.tonnage : null,
        name_of_user_for_gps:
          deviceTypeDetail?.id === 1 ? data.vehicle_license_plate : null, // do tên người với biển số xe đang dùng chung 1 Textfield
        service_package: data.service_package
      };
      console.log('dataActive ===>', dataActive);
      dispatch(activeDevice(dataActive));
      closeRef();
    }
  };

  const getStatusDevice = status => {
    switch (status) {
      case DEVICE_STATUS.ACTIVE:
        return (
          <Chip
            label={'Đã Kích hoạt'}
            variant="outlined"
            size="small"
            color="primary"
          />
        );
      case DEVICE_STATUS.INACTIVE:
        return (
          <Chip
            label={'Chưa Kích hoạt'}
            variant="outlined"
            size="small"
            color="default"
          />
        );
      default:
        return '-';
    }
  };

  const getName = id => {
    const target = listAgencies.filter(agency => {
      return agency.id == id;
    });
    return target && target[0] ? target[0].name : null;
  };

  const trim = x => {
    return x.replace(/^\(+|\)+$/gm, '');
  };

  const handleSelectVehicle = e => {
    const target = vehicleType.find(type => {
      return type.id === Number(e.target.value);
    });
    if (target) {
      setDeviceTypeDetail(target);
      setSelectedSlots(target?.slots);
      setSelectedTonnage(target?.tonnage);
    }
  };

  const handleChangePhoneNumber = e => {
    let searchData = trim(e.target.value);
    setSelectedPhone(searchData);
    if (searchData && searchData.length === 10) {
      dispatch(getUsernameByPhoneNumber({ phoneNumber: searchData }));
    }
  };

  useEffect(() => {
    dispatch(getListAgencies());
    return () => {
      dispatch(resetSearchUsernameByPhone());
    };
  }, [dispatch]);

  return (
    <div>
      <Formik
        initialValues={initValue}
        validationSchema={Yup.object().shape({
          // vehicle_license_plate: Yup.string()
          //   .max(100)
          //   .required('Biển số không được để trống'),
          // phone: Yup.string()
          //   .matches(
          //     /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
          //     'Số điện thoại chưa đúng định dạng'
          //   )
          //   .required('Số điện thoại không được để trống'),
        })}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={6}>
                <InputLabel>
                  Tài khoản người dùng <span className="text-danger">*</span>
                </InputLabel>
                <TextField
                  size="small"
                  className="input-no-fieldset"
                  fullWidth
                  error={Boolean(
                    touched.phone &&
                      errors.phone
                  )}
                  helperText={
                    touched.phone &&
                    errors.phone
                  }
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={e => handleChangePhoneNumber(e)}
                  value={selectedPhone}
                  variant="outlined"
                />
                <>     
                  {/* {listDevice && <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    size="small"
                    disableClearable
                    options={listDevice}
                    getOptionLabel={option => option.serial}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span style={{ marginRight: '1em' }}>{getStatusDevice(option.status)} </span>
                        {option.serial}
                      </React.Fragment>
                    )}
                    name="device_id"
                    onChange={(e, value) => setFieldValue('device_id', value.id)}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        className="input-no-fieldset"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />} */}
                </>
              </Grid>
              <Grid item md={6} xs={6}>
                <InputLabel>
                  Tên người dùng <span className="text-danger">*</span>
                </InputLabel>
                <TextField
                  size="small"
                  disabled={true}
                  className="input-no-fieldset"
                  fullWidth
                  margin="normal"
                  name="full_name"
                  onBlur={handleBlur}
                  value={username}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <InputLabel>
                  Serial thiết bị <span className="text-danger">*</span>
                </InputLabel>
                <TextField
                  disabled={true}
                  size="small"
                  className="input-no-fieldset"
                  fullWidth
                  margin="normal"
                  name="serial"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.serial}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <InputLabel>
                  Loại phương tiện <span className="text-danger">*</span>
                </InputLabel>
                <TextField
                  className="input-no-fieldset"
                  size="small"
                  fullWidth
                  select
                  margin="normal"
                  name="title_device_type"
                  onBlur={handleBlur}
                  onChange={e => handleSelectVehicle(e)}
                  SelectProps={{
                    native: true
                  }}
                  //value={values.agency_id}
                  variant="outlined"
                  placeholder="Chọn loại phương tiện"
                >
                  <option>Chọn loại phương tiện</option>
                  {vehicleType &&
                    vehicleType.map(type => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={6}>
                <InputLabel>
                  {deviceTypeDetail?.id !== 1
                    ? 'Biển số xe'
                    : 'Tên người'}
                  <span className="text-danger"> *</span>
                </InputLabel>
                <TextField
                  size="small"
                  className="input-no-fieldset"
                  error={Boolean(
                    touched.vehicle_license_plate &&
                      errors.vehicle_license_plate
                  )}
                  fullWidth
                  helperText={
                    touched.vehicle_license_plate &&
                    errors.vehicle_license_plate
                  }
                  margin="normal"
                  name="vehicle_license_plate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vehicle_license_plate}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <InputLabel>
                  Phiên bản <span className="text-danger">*</span>
                </InputLabel>
                <TextField
                  disabled={true}
                  size="small"
                  className="input-no-fieldset"
                  fullWidth
                  margin="normal"
                  name="version"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.version}
                  variant="outlined"
                />
              </Grid>
              {deviceTypeDetail.id === 6 && (
                <Grid item md={6} xs={6}>
                  <InputLabel>
                    Tải trọng <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    margin="normal"
                    name="tonnage"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    SelectProps={{
                      native: true
                    }}
                    value={values.tonnage}
                    variant="outlined"
                  >
                    <option>Chọn loại tải trọng</option>
                    {selectedTonnage &&
                      selectedTonnage.map(type => (
                        <option key={type.id} value={type.value}>
                          {type.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>
              )}

              {deviceTypeDetail.id === 4 && (
                <Grid item md={6} xs={6}>
                  <InputLabel>
                    Số chỗ <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    margin="normal"
                    name="slots"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    SelectProps={{
                      native: true
                    }}
                    value={values.slots}
                    variant="outlined"
                  >
                    <option>Chọn số chỗ</option>
                    {selectedSlots &&
                      selectedSlots.map(type => (
                        <option key={type.id} value={type.value}>
                          {type.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>
              )}

              <Grid item md={6} xs={6}>
                <InputLabel>
                  Gói cước <span className="text-danger">*</span>{' '}
                </InputLabel>
                <TextField
                  size="small"
                  className="input-no-fieldset"
                  // error={Boolean(
                  //   touched.vehicle_license_plate &&
                  //     errors.vehicle_license_plate
                  // )}
                  fullWidth
                  // helperText={
                  //   touched.vehicle_license_plate &&
                  //   errors.vehicle_license_plate
                  // }
                  margin="normal"
                  name="service_package"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.service_package}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box my={3}>
              <div className={classes.groupButtonSubmit}>
                <div className={classes.wrapper}>
                  <Button
                    style={{
                      marginRight: '10px',
                      textTranform: 'none !important'
                    }}
                    onClick={() => closeRef()}
                    className="btn-main btn-plain mx-3"
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    Thoát
                  </Button>
                  <Button
                    style={{
                      marginRight: '10px',
                      textTranform: 'none !important'
                    }}
                    className="btn-main mx-3"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Cập nhật
                  </Button>
                  {statusActive === STATUS_API.PENDING && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  formHeader: {
    padding: theme.spacing(3)
  },
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  avatar: {
    height: 100,
    width: 100
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  disableForm: {
    pointerEvents: 'none'
  },
  colorWhite: {
    color: '#fff'
  }
}));

export default DeviceActiveForm;
