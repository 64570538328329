import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  AppBar,
  Container,
  Dialog,
  makeStyles,
  Slide,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { resetChange } from 'src/features/vehicleSlice';
import DeviceTransferForm from './device_transfer';
import HeaderModal from 'src/app/components/headerModal/HeaderModal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const params = {
  page: 1,
  page_size: PAGE_SIZE_LIST,
  is_pagination: true
};

const TransferDevice = ({ open, rowsSelected, closeRef }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    if (!closeRef) return;
    dispatch(resetChange())
    closeRef();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
      >
        <div>
          <AppBar style={{
              position: 'relative',
              boxShadow: 'unset !important'
          }}>
            <HeaderModal title={'Chuyển xuống đại lý'} closeRef={handleClose}/>
          </AppBar>
          <Container maxWidth="md">
            <DeviceTransferForm
              closeRef={closeRef}
              rowsSelected={rowsSelected}
            />
          </Container>
        </div>
      </Dialog>
    </div>
  );
}
 
export default React.memo(TransferDevice);