import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const HeaderModal = ({title, closeRef}) => {
    const classes = useStyles();
    const handleClose = (isSave) => {
        if(!closeRef) return;
        closeRef(isSave);
    };
    return (
        <Toolbar className="device-active-toolbar">
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      );
}

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
      backgroundColor: 'none !important'
    },
    title: {
      fontWeight: 600,
      fontSize: 18,
      flex: 1,
    }
}));
 
export default HeaderModal;