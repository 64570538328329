
export class User {
  constructor() {
    this.username = ''
    this.full_name = ''
    this.email = ''
    this.phone = ''
    this.address = ''
    this.password = ''
    this.citizen_id = ''
  }
}
