import React, {
  useState,
  useEffect,
  useRef,
 
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MenuBoxTracking from 'src/app/components/maps/MenuBoxTracking';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import './style.css';
import { Box } from '@material-ui/core';
import {
  getListVehicle,
  getListVehicleTracking,
  getListVehicleTrackingAdmin,
  getVehicleLicensePlateByAdmin
} from 'src/features/vehicleSlice';
import _orderBy from 'lodash/orderBy';
import { GetUserInfo } from 'src/features/authSlice';
import RotateIcon from 'src/app/utils/RotateIcon';
import { makeStyles, Typography } from '@material-ui/core';

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  Tooltip,
  LayersControl,
  useMap
} from 'react-leaflet';
import L from 'leaflet';
import mkIcon from '../../assets/mkicon.png';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { getIconStatus } from 'src/app/utils/vehicleService';
import LicensePlateTag from 'src/app/components/licensePlateTag/LicesePlateTag';
import ContentCarInfo from 'src/app/components/popupInfocar/ContentCarInfo';


const ResetView = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    const interval = setInterval(() => {
      map.setView(center);
    }, 5000); // 10s

    return () => clearInterval(interval); 
  }, [center, map]);

  return null;
};

const MapOsm = () => {
  const [mapRef, setMapRef] = useState(null);
  const markerRef = useRef(null);
  const dispatch = useDispatch();

  const listVehicleTracking = useSelector(
    state => state.vehicleSlice.listVehicleTracking
  );

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  const statisticVehicleTracking = useSelector(state => state.vehicleSlice.statisticVehicleTracking);

  const [params, setParams] = useState({
    page: 1,
    pageSize: 20
  })

  useEffect(() => {
    if(isAdmin) {
      dispatch(getListVehicle(params));
      var i1 = window.setInterval(() => {
        dispatch(getListVehicle(params));
      }, 10000);
    } else if(isAgency) {
      const newParams = Object.assign({}, params, {agency_id: agencyID});
      dispatch(getListVehicle(newParams));
      var i2 = window.setInterval(() => {
        dispatch(getListVehicle(newParams));
      }, 10000);
    }
    return () => {
      window.clearInterval(i1);
      window.clearInterval(i2);
    }
  }, [isAdmin, isAgency]);


  useEffect(() => {
    if (isAdmin) {
      dispatch(getListVehicleTracking(''));
      var i3 = window.setInterval(() => {
        dispatch(getListVehicleTracking(''));
      }, 5000);
    }else{
      dispatch(getListVehicleTracking(`/agency/${agencyID}`));
      var i4 = window.setInterval(() => {
        dispatch(getListVehicleTracking(`/agency/${agencyID}`));
      }, 5000);
    }
    return () => {
      window.clearInterval(i3);
      window.clearInterval(i4);
    };
  }, [isAdmin, isAgency]);

  useEffect(() => {
    dispatch(getVehicleLicensePlateByAdmin());
  }, []);

  const trim = x => {
    return x.replace(/^\(+|\)+$/gm, '');
  };

  const centerDefaultLocal = localStorage.getItem('center')
    ? trim(localStorage.getItem('center')).split(', ')
    : [21.024683, 105.832495];

  const centerDefault = [
    Number(centerDefaultLocal[0]),
    Number(centerDefaultLocal[1])
  ];

  const statusGetAll = useSelector(state => state.vehicleSlice.statusGetAll);
  const [positionsInfoBox, setPositionsInfoBox] = useState({});
  const [center, setCenter] = useState(centerDefault);
  const [showMenu, setShowMenu] = useState(true);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [vehicleSelected, setVehicleSelected] = useState({});
  const [carStatus, setCarStatus] = useState('');

  const handleMarkerClick = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      setPositionsInfoBox({
        license_plate: vehicle.license_plate,
        lat: Number(vehicle.lat),
        lng: Number(vehicle.lng),
        created_at: vehicle.created_at,
        air_condition_status: vehicle.air_condition_status,
        engine_status: vehicle.engine_status,
        battery: vehicle.battery,
        charge_status: vehicle.charge_status,
        speed_gps: vehicle.speed_gps,
        door_status: vehicle.door_status,
        stop_times: vehicle.stop_times, // số lần dừng đỗ
        is_stopping: vehicle.is_stopping, // đang dừng đỗ
        total_distance: vehicle.total_distance, // Tổng quãng đường đi được trong ngày
        total_overspeed: vehicle.total_overspeed, // số lần quá tốc độ
        total_drive_time: vehicle.total_drive_time, // thời gian lái xe trong ngày
        driver_login: vehicle.driver_login,
        driver_logout: vehicle.driver_logout,
        driver_name: vehicle.driver_name, // tên lái xe
        driver_license: vehicle.driver_license, // bằng lái xe
        non_stop_driving_time: vehicle.non_stop_driving_time
      });
    }
  };

  const getVehicle = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      setVehicleSelected(vehicle);
      setCenter([Number(vehicle.lat), Number(vehicle.lng)]);
      mapRef.setView([Number(vehicle.lat), Number(vehicle.lng)], 15);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCenter([position.coords.latitude, position.coords.longitude]);
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude
        ]);

        if (!mapRef) return;
        mapRef.flyTo(
          [position.coords.latitude, position.coords.longitude],
          15,
          {
            animate: true
          }
        );
      });
    }
  };

  //#region Display Icons
  const handleDisplayIcon =
    (vehicle, statisticVehicleTracking) => {
      const iconVehicle = getIconStatus(
        vehicle.device_status,
        vehicle.device_type
      );
    const vehicleStates = [
      {
        list: 'lost_gsm_vehicles',
          icon: iconVehicle
      },
      {
        list: 'over_speed_vehicles',
          icon: iconVehicle
      },
      {
        list: 'stop_vehicles',
          icon: iconVehicle
      },
      {
        list: 'running_vehicles',
          icon: iconVehicle
      },
      {
        list: 'lost_gps_vehicles',
          icon: iconVehicle
        }
    ];

      let makerIcon;
  
    if (vehicle && statisticVehicleTracking) {
      for (const { list, icon } of vehicleStates) {
        if (statisticVehicleTracking[list]?.includes(vehicle.license_plate)) {
          let iconObject = RotateIcon.makeIcon(icon);
          if (typeof iconObject.setRotation === 'function') {
            iconObject = iconObject.setRotation({ deg: vehicle.direction });
          }
          const url =
            typeof iconObject.getUrl === 'function'
              ? iconObject.getUrl()
              : mkIcon;
            makerIcon = L.icon({
              iconUrl: url,
              //  iconSize: [37, 35],
              iconAnchor: [0, 0],
              // popupAnchor: [0, 0]
          });
          break;
        }
      }
    }
      return makerIcon;
  };

  const displayLocationIcon = () => {
    let locationCurrent;
    locationCurrent = L.icon({
      iconUrl: `/static/iconSvg/gps.svg`,
      iconSize: [25, 25],
      iconAnchor: [0, 25]
    });
    return locationCurrent;
  };

  return (
    <>
      <Box className="show_menu">
        <Box
          title="Hiện Menu"
          onClick={() => setShowMenu(true)}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 0.3s ease-in'
          }}
        >
          <ArrowRight />
        </Box>
      </Box>

      <Box
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: 22,
          left: 384,
          zIndex: 999,
          backgroundColor: '#ffffff',
          borderRadius: '0 8px 8px 0',
          boxShadow: 'rgb(220,220,220) 3px 3px 6px 0px inset',
          width: '28px',
          height: '48px',
          display: showMenu ? 'block' : 'none',
          transition: 'all .3s'
        }}
      >
        <Box
          title="Ẩn Menu"
          onClick={() => setShowMenu(false)}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ArrowLeft />
        </Box>
      </Box>

      <Box
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          cursor: 'pointer',
          boxShadow: 'rgba(20, 20, 20, 0.322)  0px 0px 2px 0px',
          border: '0px !important',
          padding: '8px',
          color: 'red',
          width: '45px',
          height: '45px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '50%',
          zIndex: 1000
        }}
        onClick={() => getCurrentLocation()}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src="/static/iconSvg/locationIconv2.svg" />
          {/* <GpsFixedIcon /> */}
        </Box>
      </Box>

      <MenuBoxTracking
        getVehicle={getVehicle}
        statusGetAll={statusGetAll}
        showMenu={showMenu}
        setCarStatus={setCarStatus}
        carStatus={carStatus}
      />

      <MapContainer
        center={center}
        zoom={13}
        maxZoom={18}
        minZoom={6}
        style={{ height: 'calc(100vh - 66px)', position: 'relative', width:'100%' }}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={mapR => setMapRef(mapR)}
        zoomAnimation={true}
      >
        <LayersControl>

        <LayersControl.BaseLayer name='Open Street Map'>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
         
          <LayersControl.BaseLayer name='Vệ tinh'>
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer checked name='Google Map'>
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>

        </LayersControl>
        <FullscreenControl forceSeparateButton={true} position="topright" />
        <ZoomControl position="topright" />

        {listVehicleTracking && listVehicleTracking.length > 0
          ? listVehicleTracking.map((vehicle, index) => {
            
                if (carStatus && carStatus === vehicle.device_status) {
                  return (
                    <>
                      {vehicle && vehicle.lat && vehicle.lng ? (
                        <Marker
                          key={index}
                          position={[Number(vehicle.lat), Number(vehicle.lng)]}
                          eventHandlers={{
                            click: () => handleMarkerClick(vehicle)
                          }}
                          icon={handleDisplayIcon(
                            vehicle,
                            statisticVehicleTracking
                          )}
                          ref={markerRef}
                        >
                          <Popup
                            position={[
                              Number(vehicle.lat),
                              Number(vehicle.lng)
                            ]}
                            keepInView={true}
                            autoPanPaddingTopLeft={[20, 40]}
                          >
                            <ContentCarInfo
                              positionsInfoBox={positionsInfoBox}
                            />
                          </Popup>

                          <LicensePlateTag
                            position={vehicle}
                            license_plate={vehicle.license_plate}
                          />
                        </Marker>
                      ) : null}
                    </>
                  );
                }else {
                return (
                  <Box>
                    {vehicle && vehicle.lat && vehicle.lng ? (
                      <>
                        <Marker
                          key={index}
                          options={{
                            rotation: 180
                          }}
                          position={[Number(vehicle.lat), Number(vehicle.lng)]}
                          icon={handleDisplayIcon(
                            vehicle,
                            statisticVehicleTracking,
                          )}
                          eventHandlers={{
                            click: e => handleMarkerClick(vehicle)
                          }}
                          ref={markerRef}
                        >
                          <Popup
                            position={[
                              Number(vehicle.lat),
                              Number(vehicle.lng)
                            ]}
                            keepInView={true}
                            autoPanPaddingTopLeft={[20, 40]}
                          >
                            <ContentCarInfo
                              positionsInfoBox={positionsInfoBox}
                              vehicle = {vehicle}
                            />
                          </Popup>

                          {vehicleSelected.license_plate ===
                          vehicle.license_plate ? (
                            <Tooltip
                              direction="center"
                              offset={[21, 22]}
                              position={[
                                Number(vehicle.lat),
                                Number(vehicle.lng)
                              ]}
                              opacity={1}
                              permanent={true}
                            >
                              <Box>
                                <img src="/static/iconSvg/focusIcon.svg" />
                              </Box>
                              <Typography
                                style={{
                                  backgroundColor: '#F5FAFF',
                                  padding: '1px 4px',
                                  fontSize: '12px',
                                  width: 'max-content !important',
                                  borderRadius: '0.1em',
                                  color: 'rgb(0, 0, 0)',
                                  fontWeight: 'bold',
                                  marginTop: '0px',
                                  marginLeft: '-3px'
                                }}
                              >
                                {vehicle.license_plate}
                              </Typography>
                              <Box>
                                 <ResetView center={[Number(vehicle.lat),Number(vehicle.lng)]} />
                              </Box>
                            </Tooltip>
                          ) : (
                            <LicensePlateTag
                              position={vehicle}
                              license_plate={vehicle.license_plate}
                            />
                          )}
                        </Marker>
                      </>
                    ) : null}
                  </Box>
                );
              }
            })
          : null}

        {currentLocation && (
          <Marker icon={displayLocationIcon()} position={currentLocation}>
            <Tooltip
              style={{
                background: 'rgba(0, 0, 0, 0) !important',
                boxShadow: 'none !important'
              }}
              position={currentLocation}
              direction="center"
              permanent
            >
              <Box
                style={{
                  backgroundColor: 'rgba(19, 19, 19, 0.493)',
                  color: 'white',
                  borderRadius: '5px',
                  padding: '2px',
                  position: 'absolute',
                  top: 5,
                  left: -22
                }}
              >
                {' '}
                Vị trí của bạn
              </Box>
            </Tooltip>
          </Marker>
        )}
      </MapContainer>
    </>
  );
};

const useStyles = makeStyles(theme => ({}));

export default React.memo(MapOsm);
