import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  Button,
  Popover,
  Fade,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import _size from 'lodash/size';
import ModalConfirm from 'src/app/components/ModalConfirm';
import {
  closeDialogConfirm,
  showDialogConfirm,
  showToast
} from 'src/features/uiSlice';
import {
  ACTION_TABLE,
  messageToastType_const,
  PAGE_SIZE_LIST,
  STATUS_API,
  DEVICE_STATUS,
  STORE_STATUS
} from 'src/app/constant/config';
import { MESSAGE } from 'src/app/constant/message';
import { useNavigate, useLocation } from 'react-router-dom';
import { _convertObjectToQuery } from 'src/app/utils/apiService';
import { Pagination } from '@material-ui/lab';

import LoadingComponent from 'src/app/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import _remove from 'lodash/remove';
import {
  updateMultiDevices,
  deleteDevices,
  recallDevices
} from 'src/features/deviceSlice';
import { getListSales } from 'src/features/userSlice';
import DialogConfirm from 'src/app/components/DialogConfirm';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import './style.css';
import EditDevice from './edit';
import TransferDevice from './transfer';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Results = ({
  className,
  isLoading,
  getListDeviceRef,
  actionDetailsDeviceRef,
  totalDevice,
  activeNewDevice,
  ...rest
}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [sellerSelected, setSellerSelected] = useState({});
  const [showSellerLists, setShowSellerLists] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openRecallModal, setOpenRecallModal] = useState(false);
  const [itemCurrent, setItemCurrent] = useState({});

  const [params, setParams] = useState({
    page: query.get('page') || page,
    page_size: query.get('page_size') || limit
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const listDevice = useSelector(state => state.deviceSlice.listDevice);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (query.get('page')) {
      setPage(query.get('page'));
    }
    if (query.get('page_size')) {
      setLimit(query.get('page_size'));
    }
  }, []);

  const checked = id => {
    return rowsSelected.indexOf(id) !== -1;
  };

  const select = (deviceId, device) => {
    activeNewDevice(device);
    let rowIndex = rowsSelected.indexOf(deviceId);
    if (rowIndex === -1) {
      const newSelected = [deviceId, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== deviceId);
      setRowsSelected(newSelected);
    }
  };

  const handleDeleteMultiDevices = () => {
    dispatch(showDialogConfirm());
  };

  const confirmDeleteDevices = () => {
    if (rowsSelected.length > 0) {
      dispatch(deleteDevices({ list_device_ids: rowsSelected }));
      setRowsSelected([]);
    }
  };

  const handleDeleteDevice = id => {
    dispatch(showDialogConfirm());
    if (id) setRowsSelected([id]);
    handleCloseMenu();
  };

  const getStatusDevice = status => {
    switch (status) {
      case DEVICE_STATUS.ACTIVE:
        return (
          <span
            className="badge-actived badge"
            style={{
              backgroundColor: '#d4efdf',
              color: '#3DB670'
            }}
          >
            Kích hoạt
          </span>
        );
      case DEVICE_STATUS.INACTIVE:
        return (
          <span
            className="badge-inActived badge"
            style={{
              backgroundColor: '#E7E8EE',
              color: '#475461'
            }}
          >
            Lưu kho
          </span>
        );
      default:
        return '-';
    }
  };

  const getSlotsOrTonnage = (vehicleType, device) => {
    switch (vehicleType) {
      case 'Xe khách':
        return <span> {device.vehicle?.vehicle_type?.slots} chỗ </span>;
      case 'Xe tải':
        return <span> {device.vehicle?.vehicle_type?.tonnage} tấn</span>;
      default:
        return '--';
    }
  };

  const getListSeller = async () => {
    setShowSellerLists(true);
    dispatch(getListSales());
  };

  const changeDevicesStatus = () => {
    if (_size(sellerSelected) > 0 && rowsSelected.length > 0) {
      dispatch(
        updateMultiDevices({
          sale_id: sellerSelected.id,
          list_device_id: rowsSelected
        })
      );
    }
  };

  const confirmSold = () => {
    if (_size(sellerSelected) > 0 && rowsSelected.length > 0) {
      changeDevicesStatus();

      setOpenConfirm(false);
      setRowsSelected([]);
      setSellerSelected({});
    }
  };

  const confirmRecall = () => {
    if (rowsSelected.length > 0) {
      dispatch(
        recallDevices({
          list_device_id: rowsSelected
        })
      );
      setRowsSelected([]);
      setOpenRecallModal(false);
    }
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
    if (!getListDeviceRef) return;
    const newparams = Object.assign({}, params, {
      page_size: event.target.value,
      page: 1
    });
    setParams(newparams);
    // navigate(`/app/device?${_convertObjectToQuery(newparams)}`);
    getListDeviceRef(newparams);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListDeviceRef) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    // navigate(`/app/device?${_convertObjectToQuery(newparams)}`);

    getListDeviceRef(newparams);
  };

  const handleClickMenu = (event, device) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent(device);
  };

  const closeModalEdit = () => {
    setOpenEditModal(false);
    setItemCurrent({});
  };

  const closeModalTransfer = () => {
    setOpenTransferModal(false);
    setItemCurrent({});
  };

  const handleCloseConfirm = () => {
    setRowsSelected([]);
    setItemCurrent({});
    return dispatch(closeDialogConfirm());
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }
    if (listDevice.length > 0 && rowsSelected.length !== listDevice.length) {
      const all = listDevice.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };

  const handleEditDevice = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleTransferDevice = id => {
    setOpenTransferModal(true);
    setRowsSelected([id]);
    handleCloseMenu();
  };

  return (
    <>
      <ModalConfirm
        title={`Thu hồi thiết bị`}
        content={`Bạn có muốn thu hồi ${rowsSelected.length} thiết bị không ?`}
        textOk={'Đồng ý'}
        textCancel={'Hủy'}
        open={openRecallModal}
        setOpen={setOpenRecallModal}
        callbackOk={confirmRecall}
      />

      <EditDevice
        open={openEditModal}
        device={itemCurrent}
        closeRef={closeModalEdit}
      />

      <DialogConfirm
        title={`Bạn có muốn xóa ${rowsSelected.length} thiết bị không ?`}
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        callbackOk={() => confirmDeleteDevices()}
        callbackCancel={handleCloseConfirm}
      />

      <TransferDevice
        open={openTransferModal}
        rowsSelected={rowsSelected}
        closeRef={closeModalTransfer}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        <div>
          {rowsSelected.length > 0 && (
            <Fade in={rowsSelected.length > 0}>
              <Box
                justifyContent="flex-end"
                className="change-status-box p-3"
                minWidth={1050}
              >
                <span width={50}>
                  <Checkbox
                    checked={rowsSelected.length > 0}
                    onChange={() => checkAll()}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </span>

                <span
                  onClick={() => setOpenRecallModal(true)}
                  className="mx-2 btnDevice"
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  <img
                    src={`/static/iconSvg/select-seller.svg`}
                    style={{ paddingRight: '5px', paddingBottom: '4px' }}
                  />
                  <span className="pt-1"> Thu hồi </span>
                </span>

                <span
                  onClick={() => setOpenTransferModal(true)}
                  className="mx-2 btnDevice"
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  <img
                    src={`/static/iconSvg/select-seller.svg`}
                    style={{ paddingRight: '5px', paddingBottom: '4px' }}
                  />
                  <span className="pt-1"> Chuyển xuống đại lý </span>
                </span>

                <span
                  onClick={handleDeleteMultiDevices}
                  className="mx-2 btnDevice"
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  <img
                    src={`/static/iconSvg/delete-icon.svg`}
                    style={{ paddingRight: '5px', paddingBottom: '4px' }}
                  />
                  <span className="pt-1"> Xóa </span>
                </span>
              </Box>
            </Fade>
          )}

          <PerfectScrollbar>
            <Box minWidth={1050} mb={0} className="table-result">
              {isLoading ? (
                <LoadingComponent />
              ) 
              : 
              (
                <Table>

                  {rowsSelected.length === 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell width={50}>
                          <Checkbox
                            checked={rowsSelected.length > 0}
                            onChange={() => checkAll()}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </TableCell>
                        <TableCell width={160} align="center">
                          Mã thiết bị
                        </TableCell>
                        <TableCell width={200} align="center">
                          Tên/Biển số
                        </TableCell>
                        <TableCell width={300} align="center">
                          Loại phương tiện
                        </TableCell>
                        <TableCell width={150} align="center">
                          Serial
                        </TableCell>
                        <TableCell width={180} align="center">
                          Ngày sản xuất
                        </TableCell>
                        <TableCell width={160} align="center">
                          Phiên bản
                        </TableCell>
                        <TableCell width={100} align="center">
                          Trạng thái
                        </TableCell>
                        <TableCell width={180} align="center">
                          Ngày kích hoạt
                        </TableCell>
                        <TableCell width={150} align="center">
                          Hạn gói còn lại
                        </TableCell>
                        <TableCell width={150} align="center">
                          Số sim
                        </TableCell>
                        <TableCell width={140} align="center">
                          Số chỗ/ Tải trọng
                        </TableCell>
                        <TableCell width={200} align="center">
                          Người dùng
                        </TableCell>
                        <TableCell width={250} align="center">
                          Số điện thoại
                        </TableCell>
                        <TableCell width={200} align="center">
                          Đại lý
                        </TableCell>
                        <TableCell width={0} align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                  )}

                  <TableBody>
                    {listDevice && listDevice.length > 0 ? (
                      listDevice.map((device, index) => (
                        <TableRow hover key={device.id}>
                          <TableCell>
                            <Checkbox
                              checked={checked(device.id)}
                              onChange={() => select(device.id, device)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {device?.deviceType?.name || '--'}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: 'center', minWidth: '70px' }}
                          >
                            {device.vehicle?.license_plate
                              ? device.vehicle?.license_plate
                              : device.vehicle?.name || '--'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {device.vehicle?.vehicle_type?.title || '--'}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: 'center' }}
                            // className={classes.minWithColumn}
                          >
                            {device.serial || '--'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {device.date_of_manufacture
                              ? moment(device.date_of_manufacture).format(
                                  'DD-MM-yyyy'
                                )
                              : '-'}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: 'center' }}
                            // className={classes.minWithColumn}
                          >
                            {device.hardware_version || '--'}
                          </TableCell>

                          <TableCell
                            style={{ textAlign: 'center' }}
                            // style={{minWidth: '100px'}}
                          >
                            {getStatusDevice(device.status)}
                          </TableCell>

                          <TableCell style={{ textAlign: 'center' }}>
                            {moment(device.created_at).format('DD-MM-yyyy') ||
                              '--'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {'365'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {device.sim_serial || '--'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {getSlotsOrTonnage(
                              device.vehicle?.vehicle_type?.title,
                              device
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {device.agency?.leader?.full_name || '--'}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {device.agency?.leader?.phone || '--'}
                          </TableCell>

                          <TableCell
                            style={{ textAlign: 'center' }}
                            // className={classes.minWithColumn}
                          >
                            {device.agency?.name || '--'}{' '}
                            {device.agency?.agency_code
                              ? `(${device.agency.agency_code})`
                              : ''}
                          </TableCell>
                          <TableCell>
                            <span style={{ color: '#AEB1C5' }}>
                              <MoreVertIcon
                                aria-describedby={`menu-device-${device.id}`}
                                className={`cursor-pointer hover-red`}
                                onClick={e => handleClickMenu(e, device)}
                              />
                            </span>

                            <Popover
                              id={`menu-device-${device.id}`}
                              className="popover-device"
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <Typography>
                                <List>
                                  <ListItem
                                    onClick={() => handleEditDevice()}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chỉnh sửa
                                  </ListItem>
                                  <ListItem
                                    onClick={() =>
                                      handleTransferDevice(device.id)
                                    }
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chuyển xuống đại lý
                                  </ListItem>
                                  <ListItem
                                    //onClick={() => handleEditDevice()}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Gia hạn thiết bị
                                  </ListItem>
                                  <ListItem
                                    //onClick={() => handleEditDevice()}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Thu hồi thiết bị bảo hành
                                  </ListItem>
                                  <ListItem
                                    //onClick={handleCloseMenu}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chọn người bán
                                  </ListItem>
                                  <ListItem
                                    onClick={() =>handleDeleteDevice(device.id)}
                                    data-id={device.id}
                                    className="cursor-pointer list-menu-device"
                                  >
                                    <span class="text-danger "> Xóa </span>
                                  </ListItem>
                                </List>
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100vw'
                        }}
                      >
                        <Box>
                          <img
                            src="/static/empty.png"
                            class="justify-content-center"
                          />
                        </Box>
                        <Typography>
                          <h4>Danh sách trống </h4>
                        </Typography>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
        </div>
      </Card>
      {/* {listDevice && listDevice.length > 0 && page ( */}
        <div class="mt-3 mr-4 float-right">
          <Pagination
            style={{ color: '#C62222' }}
            count={Math.ceil(totalDevice / limit)}
            size="small"
            onChange={handlePageChange}
            page={Number(params.page)}
            showFirstButton
            showLastButton
          />
        </div>
      {/* )} */}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listDevice: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  },
  table: {
    minWidth: 1500
  }
}));

export default React.memo(Results);
