import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';
import moment from 'moment';
import { STATUS_API } from 'src/app/constant/config';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import VehicleTablecell from './VehicleTablecell';
import { showLoading } from 'src/features/videoSlice';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    cursor: 'pointer'
  }
}))(TableRow);

// Man Giam sat 
const VehicleTable = ({ vehicles, getVehicle, carStatus, deviceType }) => {
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowActive, setRowActive] = useState(null);
  const statusGetListVehicle = useSelector(state => state.vehicleSlice.statusGetListVehicle)
  const [allVehicles, setAllVehicles] = useState([]);

  useEffect(() => {
    setAllVehicles(vehicles);
  }, [vehicles])

  const handleRowClick = vehicle => {
    setRowActive(vehicle.device_id)
    getVehicle(vehicle);
    dispatch(showLoading());
  };
  
  const renderBody = allVehicles?.length > 0 ? 
  allVehicles?.map((row, index) => {
    const renderRow = () => (
      <StyledTableRow
        className={row.device_id === rowActive ? 'row-actived' : ''}
        key={row.vehicle_id ?? row.id ?? index + 1}
        onClick={() => handleRowClick(row)}
      >
        <VehicleTablecell row={row} index={index} />
        <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
        <TableCell align="center">
          {row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}
        </TableCell>
      </StyledTableRow>
    );
  
    const shouldRenderRow = () => {
      if (carStatus && !deviceType) return carStatus === row.device_status;
      if (!carStatus && deviceType) return deviceType === row.device_type?.name;
      if (carStatus && deviceType)
        return carStatus === row.device_status && deviceType === row.device_type?.name;
      return true;
    };
  
    if (shouldRenderRow()) {
      return <TableBody>{renderRow()}</TableBody>;
    }
    return null; 
  })
  
  : <caption> Không có thiết bị </caption>

  return (
      <TableContainer 
        component={Paper}
        className="height_table_road_map vehicle_table" 
      >
        <Table stickyHeader aria-label="sticky table" >
          <TableHead
            style={{ 
              background: '#C62222 !important', 
              color: 'white !important' 
            }}
          >
            <TableRow>
              <TableCell align="center">Tên/Biển số</TableCell>
              <TableCell align="center">Vận tốc (Km/h) </TableCell>
              <TableCell align="center">Thời gian</TableCell>
            </TableRow>
          </TableHead>
          {
            statusGetListVehicle === STATUS_API.LOADING ? 

            <caption >
              <div style={{ textAlign: 'center', padding: 20 }}> <CircularProgress /></div>
            </caption>

            :
            renderBody 
          }
        </Table>
      </TableContainer>
  );
};

export default React.memo(VehicleTable);
