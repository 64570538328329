import {  Container, Typography, Box, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },

  title: {
    color:'#C62221'
  }
  
  
}));
const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
      <Box style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <Typography variant="h3" gutterBottom className={classes.title}>
            Chính sách quyền riêng tư
          </Typography>
          <Typography variant="h3" gutterBottom className={classes.title}>
            Privacy policy 
          </Typography>
      </Box>
        <Typography variant="body1" paragraph>
          Chính sách quyền riêng tư chi tiết sau đây giải thích việc dữ liệu được thu thập trên ứng dụng của chúng tôi và dữ liệu mà chúng tôi xử lý và sử dụng như thế nào.
          Bảo vệ dữ liệu là quan trọng đối với chúng tôi, và chúng tôi rất coi trọng việc này. Chúng tôi dựa vào sự hợp tác đáng tin cậy với Người Sử Dụng (bạn) và cố gắng hết sức để làm bạn hài lòng - điều này đương nhiên cũng áp dụng cho việc xử lý dữ liệu cá nhân của bạn. Với các điều khoản bảo vệ dữ liệu này, chúng tôi muốn thông báo cho bạn về cách thức dữ liệu cá nhân của bạn được xử lý. Do đó vui lòng xem xét các thông tin sau. Các điều khoản bảo vệ dữ liệu này là phần bổ sung của Điều khoản Sử Dụng.
        </Typography>
        <Typography variant="body1" paragraph>
          Trong quá trình phát triển ứng dụng của chúng tôi và thực hiện các yêu cầu pháp lý mới, công nghệ mới hoặc để cải thiện dịch vụ của chúng tôi, có thể có các thay đổi đối với chính sách bảo mật này. Vì lý do đó chúng tôi khuyên bạn nên tham khảo chính sách bảo mật này.
        </Typography>

        <Typography variant="h4" gutterBottom className={classes.title}>
          Bảo vệ dữ liệu trên ứng dụng VGPS (Mobile App)
        </Typography>

        <Typography variant="h4" gutterBottom className={classes.title}>
          1. Người chịu trách nhiệm kiểm soát
        </Typography>
        <Box style={{marginLeft:'22px'}}>
        <Typography variant="body1" paragraph>
          Người vận hành ứng dụng này và Người kiểm soát chịu trách nhiệm về bảo vệ dữ liệu là:
        </Typography>
        <Box ml={2}>
          <Typography variant="body1">
            <b>CÔNG TY CỔ PHẦN CÔNG NGHỆ ĐIỆN TỬ VNTECH24H</b>
            <br />
            Địa chỉ: C53-05 khu đô thị Geleximco Lê Trọng Tấn, Phường Dương Nội, Quận Hà Đông, Thành phố Hà Nội<br />
            Email: hiennq@vntech24h.com<br />
            Hotline: 0934888386
          </Typography>
        </Box>
        </Box>

        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          2. Thông tin chung về xử lý dữ liệu cá nhân
        </Typography>
        <Box style={{marginLeft:'22px'}}>
          <Typography variant="body1" paragraph>
              Chính sách quyền riêng tư này áp dụng cho dữ liệu về bạn, bao gồm dữ liệu cá nhân do VGPS thu thập. Dữ liệu cá nhân là dữ liệu hoặc tổ hợp dữ liệu cá nhân thông qua đó để nhận diện bạn.
          </Typography>
          <Typography variant="body1" paragraph>
              Chúng tôi không thu thập dữ liệu cá nhân qua ứng dụng VGPS. Trong công ty của chúng tôi việc tuân thủ các quy định pháp lý và chính sách này được giám sát bởi các nhân viên bảo vệ dữ liệu của chúng tôi. Nhân viên của chúng tôi được đào tạo để xử lý dữ liệu cá nhân và cam kết bằng văn bản tuân thủ các quy định bảo vệ dữ liệu.
          </Typography>
          <Typography variant="body1" paragraph>
              Chúng tôi đề nghị bạn chú ý đến thực tế là việc truyền dữ liệu qua Internet (ví dụ: khi giao tiếp bằng e-mail) có thể liên quan đến những khoảng trống về bảo mật. Chúng tôi cố gắng bảo vệ dữ liệu của bạn khỏi bị các bên thứ ba truy cập trái phép bằng các biện pháp phòng ngừa, chẳng hạn như “ẩn danh tính”. Mặc dù có các biện pháp bảo vệ này chúng tôi cũng không thể loại trừ hoàn toàn việc can thiệp bất hợp pháp của bên thứ ba.
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          3. Thu thập và xử lý dữ liệu trong trường hợp truy cập từ Internet
        </Typography>
        <Box style={{marginLeft:'22px'}}>
          <Typography variant="body1" paragraph>
              Khi bạn truy cập ứng dụng của chúng tôi máy chủ của chúng tôi sẽ lưu tạm thời từng quyền truy cập vào file nhật ký.
              Việc xử lý hợp pháp các dữ liệu này được thực hiện với mục đích cho phép sử dụng ứng dụng (thiết lập kết nối), để bảo mật hệ thống, quản trị kỹ thuật cơ sở hạ tầng mạng và tối ưu hóa việc cung cấp Internet. Bằng cách đồng ý với chính sách bảo mật này, bạn đồng ý cho phép chúng tôi thu thập các dữ liệu này.
              Các dữ liệu cá nhân này không được xử lý ngoài các trường hợp được nêu ở trên, trừ khi bạn đồng ý rõ ràng để xử lý thêm.
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          4. Mục đích sử dụng dữ liệu
        </Typography>
        <Box style={{marginLeft:'22px'}}>
          <Typography variant="body1" paragraph>
            Theo nguyên tắc tránh và tiết kiệm dữ liệu của Quy định bảo vệ dữ liệu chung, chúng tôi chỉ thu thập dữ liệu cá nhân trên ứng dụng của chúng tôi khi cần thiết cho mục đích mong muốn của bạn, chúng tôi có nghĩa vụ phải làm như vậy theo qui định của pháp luật hoặc thỏa thuận ràng buộc, nếu chúng tôi có quyền lợi hợp pháp và/hoặc nếu bạn tự nguyện đồng ý.
          </Typography>
          <Typography variant="body1" paragraph>
            Chúng tôi xử lý và lưu dữ liệu được cung cấp chỉ khi cần thiết cho mục đích dự định và xóa chúng sau khi hoàn thành mục đích hoặc sau khi hết thời gian lưu giữ tương ứng trong phiên làm việc. Việc thu thập, xử lý hoặc sử dụng không được thực hiện cho bất kỳ mục đích nào khác.
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          5. Chúng tôi sử dụng dữ liệu của bạn như thế nào
        </Typography>
        <Box style={{marginLeft:'22px'}}>
          <Typography variant="body1" paragraph>
            Dữ liệu của bạn được truyền đi và sử dụng trong phạm vi của VGPS.
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          6. Truyền tải dữ liệu qua Internet
        </Typography>
        <Box style={{marginLeft:'22px'}}>
          <Typography variant="body1" paragraph>
            Internet là giao diện mở toàn cầu. Do thuộc tính hoạt động của internet và rủi ro hệ thống có liên quan, mọi hoạt động truyền dữ liệu được thực hiện và bạn tự chịu rủi ro. Để đảm bảo an toàn, dịch vụ triển khai cho bạn qua kênh truyền thông tin mã hóa.
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          7. Các biện pháp an ninh
        </Typography>
        <Box style={{marginLeft:'22px'}}> 
          <Typography variant="body1" paragraph>
            Chúng tôi đã thực hiện các biện pháp phòng ngừa bao quát để bảo mật dữ liệu của bạn. 
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          8. Chuyển tiếp dữ liệu cá nhân cho bên thứ ba
        </Typography>
        <Box style={{marginLeft:'22px'}}>
          <Typography variant="body1" paragraph>
            Dữ liệu cá nhân của bạn sẽ không được chuyển tiếp cho bên thứ ba (bên ngoài VGPS), trừ các dữ liệu trong phạm vi quy định các Nghị định của Chính phủ đã ban hành
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }} className={classes.title}>
          9. Liên hệ về việc bảo vệ dữ liệu
        </Typography>
        <Box style={{marginLeft:'22px'}}>
          <Typography variant="body1" paragraph>
            Nếu bạn có câu hỏi liên quan đến việc xử lý dữ liệu cá nhân của mình, bạn có thể liên hệ trực tiếp với người phụ trách bảo vệ dữ liệu của chúng tôi, họ sẵn sàng trả lời cho các yêu cầu, đơn từ hoặc khiếu nại thông tin:
          </Typography>
          <Box ml={2}>
            <Typography variant="body1">
              
            <b>CÔNG TY CỔ PHẦN CÔNG NGHỆ ĐIỆN TỬ VNTECH24H</b><br />
              Địa chỉ: C53-05 khu đô thị Geleximco Lê Trọng Tấn, Phường Dương Nội, Quận Hà Đông, Thành phố Hà Nội<br />
              Email: hiennq@vntech24h.com<br />
              Hotline: 0934888386
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
 
export default PrivacyPolicy;