import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  SvgIcon,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Logo from 'src/app/components/Logo';
import { logOutAction } from '../../../features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import './style.css';
import { MENU } from 'src/app/constant/menu';
import { roles } from 'src/app/constant/roles';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    boxShadow: '0 0 5px 0px #b3b3b3b3',
    height: '66px'
  },
  avatar: {
    width: 60,
    height: 60
  },
  menuHeader: {
    display: 'flex',
    justifyContent: 'center'
  },
  listItem: {
    listStyleType: 'none',
    margin: 0,
    padding: 0
  },
  wrapLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px'
  },
  textLogo: { fontWeight: 'bold' },
  leftTextLogo: { color: '#000000' },
  rightTextLogo: { color: '#8F0A0C' },
  itemMenu: {
    display: 'inline-block',
    padding: '20px',
    fontSize: '18px',
    fontWeight: '400',
    cursor: 'pointer',
    marginLeft: '15px',
    color: ' #475461',
    fontSize: '16px',
    transition: 'all 0.3s',
    '&:hover': {
      color: '#C62222'
    }
    // '&:nth-child(1)': {
    //   color: '#C62222',
    //   fontWeight: '800',
    // }
  },
  itemDropdownMenu: {
    color: ' #475461',
    fontSize: '15px',
    backgroundColor: '#FFFFFF',
    transition: 'all 0.3s',

    '&:hover': {
      color: '#C62222',
      backgroundColor: '#fff2f2'
      //fontWeight:'500'
    }
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [notifications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const [menuResponsive, setMenuResponsive] = React.useState(false);
  const location = useLocation();

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;

  const listMenu = isAdmin
    ? MENU
    : MENU.filter(item => item.value !== 'device' && item.value !== 'agency');

  const current = location.pathname ? location.pathname.split('/')[2] : 'map';

  const [active, setActive] = useState(current);

  const dispatch = useDispatch();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenSwitchLang = event => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLangSwitch = () => {
    setAnchorEl1(false);
  };

  const handleLogOut = () => {
    dispatch(logOutAction());
    navigate('/login');
    setAnchorEl(false);
  };

  const switchRoute = (to, query) => {
    if (typeof to === 'string') {
      setActive(to.split('/')[1]);
    }

    return navigate(`/app/${to}${query ? '?' + query : ''}`);
  };

  const renderClassMenu = menu => {
    const activeClass = menu === active ? 'itemMenu-active' : '';

    return `itemMenu ${activeClass}`;
  };
  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setMenuResponsive(open);
  };

  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const [mouseOverButtonReport, setMouseOverButtonReport] = React.useState(
    false
  );
  const [mouseOverMenuReport, setMouseOverMenuReport] = React.useState(false);

  const handleClickReport = event => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseReport = () => {
    setMouseOverButtonReport(false);
    setMouseOverMenuReport(false);
    setAnchorElReport(null);
  };

  const enterButtonReport = event => {
    setAnchorElReport(event.currentTarget);
    setMouseOverButtonReport(true);
  };

  const leaveButtonReport = () => {
    setTimeout(() => {
      setMouseOverButtonReport(false);
    }, 500);
  };

  const enterMenuReport = () => {
    setMouseOverMenuReport(true);
  };

  const leaveMenuReport = () => {
    setTimeout(() => {
      setMouseOverMenuReport(false);
    }, 300);
  };

  const openReport = mouseOverButtonReport || mouseOverMenuReport;

  // Menu manage
  const [anchorElManage, setAnchorElManage] = React.useState(null);
  const [mouseOverButtonManage, setMouseOverButtonManage] = React.useState(
    false
  );
  const [mouseOverMenuManage, setMouseOverMenuManage] = React.useState(false);

  const handleClickManage = event => {
    setAnchorElManage(event.currentTarget);
  };

  const handleCloseManage = () => {
    setMouseOverButtonManage(false);
    setMouseOverMenuManage(false);
    setAnchorElReport(null);
  };

  const enterButtonManage = event => {
    setAnchorElManage(event.currentTarget);
    setMouseOverButtonManage(true);
  };

  const leaveButtonManage = () => {
    setTimeout(() => {
      setMouseOverButtonManage(false);
    }, 500);
  };

  const enterMenuManage = event => {
    setMouseOverMenuManage(true);
  };

  const leaveMenuManage = () => {
    setTimeout(() => {
      setMouseOverMenuManage(false);
    }, 300);
  };

  const openManage = mouseOverButtonManage || mouseOverMenuManage;

  const handleClickMenuItem = (type, route) => {
    switchRoute(route);
    if (type === 'report') handleCloseReport();
    handleCloseManage();
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/" className={classes.wrapLogo}>
          <Logo />
        </RouterLink>
        <Hidden mdDown>
          <Box flexGrow={1} className={classes.menuHeader}>
            <ul className={classes.listItem}>
              {listMenu.map(menu => (
                <li
                  className={renderClassMenu(menu.value)}
                  key={menu.value}
                  onClick={() => switchRoute(menu.link)}
                >
                  {menu.label}
                </li>
              ))}
              <li
                className={`itemMenu ${
                  active === 'manage' ? 'itemMenu-active' : ''
                }`}
                aria-owns={Boolean(anchorElManage) ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={handleClickManage}
                onMouseOver={enterButtonManage}
                onMouseLeave={leaveButtonManage}
                onClose={handleCloseManage}
              >
                <span> Quản trị</span>
                {active === 'manage' ?
                 (<span style={{marginLeft:'6px'}}>
                 <img src={'/static/iconSvg/drop_down_active.svg'} />
               </span>)
                :
                (<span style={{marginLeft:'6px'}}>
                  <img src={'/static/iconSvg/drop_down.svg'} />
                </span>)
                }
                
              </li>

              <Menu
                style={{ marginTop: '40px', marginLeft: '24px' }}
                id="simple-menu"
                aria-describedby="simple-menu"
                aria-controls="simple-menu"
                keepMounted
                anchorEl={anchorElManage}
                open={openManage}
                onClose={handleCloseManage}
                transitionDuration={200}
                MenuListProps={{
                  onMouseOver: enterMenuManage,
                  onMouseLeave: leaveMenuManage
                }}
              >
                {isAdmin ? 
                <>
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('manage', '/manage/statistics')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Thống kê
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => handleClickMenuItem('manage', '/manage/users')}
                  className={classes.itemDropdownMenu}
                >
                  Quản trị người dùng
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('manage', '/manage/agencies')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị đại lý
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('manage', '/manage/service-staffs')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị nhân viên
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('manage', '/manage/devices')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị thiết bị
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('manage', '/manage/driver')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị lái xe
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem(
                      'manage',
                      '/manage/broadcasts-schedules'
                    )
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị quảng cáo
                </MenuItem>
                </>
                :
                <>
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('manage', '/manage/devices')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị thiết bị
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('manage', '/manage/driver')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị lái xe
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem(
                      'manage',
                      '/manage/broadcasts-schedules'
                    )
                  }
                  className={classes.itemDropdownMenu}
                >
                  Quản trị quảng cáo
                </MenuItem>
                </>
                }
               
                
              </Menu>

              <li
                className={`itemMenu ${
                  active === 'baocao-dn' || active === 'baocao-bgt'
                    ? 'itemMenu-active'
                    : ''
                }`}
                aria-owns={Boolean(anchorElReport) ? 'simple-menu' : null}
                aria-haspopup="true"
                aria-describedby="simple-menu"
                aria-controls="simple-menu"
                onClick={handleClickReport}
                onMouseOver={enterButtonReport}
                onMouseLeave={leaveButtonReport}
                onClose={handleCloseReport}
                style={{ cursor: 'pointer' }}
              >
                <span> Báo cáo</span>
                {active === 'baocao-dn' || active === 'baocao-bgt' ?
                 (<span style={{marginLeft:'6px'}}>
                 <img src={'/static/iconSvg/drop_down_active.svg'} />
               </span>)
                :
                (<span style={{marginLeft:'6px'}}>
                  <img src={'/static/iconSvg/drop_down.svg'} />
                </span>)
                }
              </li>
              <Menu
                style={{ marginTop: '40px', marginLeft: '24px' }}
                id="simple-menu"
                anchorEl={anchorElReport}
                open={openReport}
                onClose={handleCloseReport}
                transitionDuration={200}
                MenuListProps={{
                  onMouseEnter: enterMenuReport,
                  onMouseLeave: leaveMenuReport
                }}
                keepMounted
              >
                <MenuItem
                  onClick={() =>
                    handleClickMenuItem('report', '/baocao-bgt/tt073/')
                  }
                  className={classes.itemDropdownMenu}
                >
                  Báo cáo Bộ GTVT
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => handleClickMenuItem('report', '/baocao-bgt/baocao-dn')}
                  className={classes.itemDropdownMenu}
                >
                  Báo cáo doanh nghiệp
                </MenuItem>
              </Menu>
            </ul>
          </Box>
        </Hidden>
        <Hidden mdDown>
          {/* <IconButton
            className="menu-right-icon"
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <SvgIcon
              fontSize="small"
              color="action"
              style={{ width: '20px', borderRadius: '50%' }}
            >
              <SearchIcon />
            </SvgIcon>
          </IconButton> */}
          <IconButton className="menu-right-icon">
            <Badge
              style={{ width: '20px', height: '20px', borderRadius: '50%' }}
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon
                style={{ width: '20px', borderRadius: '50%' }}
              />
            </Badge>
          </IconButton>

          <IconButton
            className="menu-right-icon"
            aria-controls="lang-menu"
            aria-haspopup="true"
            onClick={handleOpenSwitchLang}
          >
            <img
              style={{ width: '20px', borderRadius: '50%' }}
              alt="Language "
              src="/static/images/vn-square-flag.png"
            />
          </IconButton>
          <Menu
            id="lang-menu"
            anchorEl={anchorEl1}
            keepMounted
            open={Boolean(anchorEl1)}
            onClose={handleCloseLangSwitch}
          >
            <MenuItem onClick={() => {}}>
              <img
                style={{
                  width: '20px',
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
                alt="Language "
                src="/static/images/en-square-flag.png"
              />{' '}
              English
            </MenuItem>
            <MenuItem onClick={() => {}}>
              <img
                style={{
                  width: '20px',
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
                alt="Language "
                src="/static/images/cn-square-flag.png"
              />
              中文
            </MenuItem>
            <MenuItem onClick={() => {}}>
              <img
                style={{
                  width: '20px',
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
                alt="Language "
                src="/static/images/lao-square-flag.png"
              />
              ພາສາລາວ
            </MenuItem>
          </Menu>
          <IconButton
            className="menu-right-icon"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <img
              style={{ width: '20px', borderRadius: '50%' }}
              alt="Logo"
              src="/static/images/no-avatar.png"
            />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem> Xin chào! {dataLogin && dataLogin.full_name}</MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/app/account');
                handleClose();
              }}
            >
              My account
            </MenuItem>
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </Menu>
        </Hidden>

        <Hidden lgUp>
          <Box width={1} textAlign={'right'}>
            <IconButton
              className="menu-right-icon"
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </IconButton>
            <IconButton
              className="menu-right-icon"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <img
                style={{ width: '20px' }}
                alt="Logo"
                src="/static/images/no-avatar.png"
              />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigate('/app/account')}>
                My account
              </MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
            <IconButton
              className="menu-right-icon"
              aria-controls="lang-menu"
              aria-haspopup="true"
              onClick={handleOpenSwitchLang}
            >
              <img
                style={{ width: '20px', borderRadius: '50%' }}
                alt="Language "
                src="/static/images/vn-square-flag.png"
              />
            </IconButton>
            <Menu
              id="lang-menu"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleCloseLangSwitch}
            >
              <MenuItem onClick={() => {}}>
                <img
                  style={{
                    width: '20px',
                    borderRadius: '50%',
                    marginRight: '5px'
                  }}
                  alt="Language "
                  src="/static/images/en-square-flag.png"
                />{' '}
                English
              </MenuItem>
              <MenuItem onClick={() => {}}>
                <img
                  style={{
                    width: '20px',
                    borderRadius: '50%',
                    marginRight: '5px'
                  }}
                  alt="Language "
                  src="/static/images/cn-square-flag.png"
                />
                中文
              </MenuItem>
              <MenuItem onClick={() => {}}>
                <img
                  style={{
                    width: '20px',
                    borderRadius: '50%',
                    marginRight: '5px'
                  }}
                  alt="Language "
                  src="/static/images/lao-square-flag.png"
                />
                ພາສາລາວ
              </MenuItem>
            </Menu>

            <IconButton
              // onClick={onMobileNavOpen}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Hidden>

        <Drawer
          open={menuResponsive}
          anchor={'left'}
          onClose={toggleDrawer(false)}
        >
          <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <RouterLink to="/" className={classes.wrapLogo}>
              <Logo />
            </RouterLink>
            <List style={{ width: 350 }}>
              {listMenu.map(menu => (
                <ListItem button key={menu.value}>
                  <ListItemText
                    primary={menu.label}
                    className={renderClassMenu(menu.value)}
                    onClick={() => switchRoute(menu.link)}
                  />
                  <Divider />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
