import React from 'react';
import { Box } from '@material-ui/core';

import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import { useNavigate, useLocation } from 'react-router';

export const ButtonShowMenu = ({ setShowMenu , showMenu}) => {
  const navigate = useNavigate();
  const pathName = location.pathname;
  const device_serial = new URLSearchParams(useLocation().search).get('device_serial');
  const handleClick = () => {
    if(pathName === '/app/camera/streaming'){
      navigate(`/app/camera/streaming?device_serial=${device_serial}&show_menu=${true}`);
      setShowMenu(true);
    }else{
      navigate(`/app/camera/playback?show_menu=${true}`);
      setShowMenu(true);
    }
  }
  return (
    <Box
      style={{
        position: 'absolute',
        left: 0,
        top: 100,
        backgroundColor: 'white',
        borderRadius: '0 8px 8px 0',
        zIndex: 12,
        cursor: 'pointer',
        boxShadow: 'rgb(158 158 158 / 87%) 7px 9px 15px 0px',
        width: '28px',
        height: '48px'
      }}
    >
      <Box
        title="Hiện Menu"
        onClick={handleClick}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ArrowRight />
      </Box>
    </Box>
  );
};
export const ButtonHideMenu = ({ showMenu, setShowMenu }) => {
  let location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const device_serial = new URLSearchParams(useLocation().search).get('device_serial');
  const handleClick = () => {
    if(pathName === '/app/camera/streaming'){
      setShowMenu(false);
      navigate(`/app/camera/streaming?device_serial=${device_serial}&show_menu=${false}`);
    }else{
      setShowMenu(false);
      navigate(`/app/camera/playback?show_menu=${false}`);
    }
   
  }
  return (
    <Box
      style={{
        position: 'absolute',
        cursor: 'pointer',
        top: 100,
        left: 384,
        backgroundColor: '#ffffff',
        borderRadius: '0 8px 8px 0',
        boxShadow:
          'rgb(220,220,220) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset',
        width: '28px',
        height: '48px',
        display: showMenu ? 'block' : 'none',
        transition: 'all 0.3s ease-in'
      }}
    >
      <Box
        title="Ẩn Menu"
        onClick={handleClick}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ArrowLeft />
      </Box>
    </Box>
  );
};
