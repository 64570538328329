import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Grid, makeStyles, Box } from '@material-ui/core';
import './style.css';
import Loading from 'src/app/components/Loading';
import { GetUserInfo } from 'src/features/authSlice';
import {
  getListVehicleTracking,

} from 'src/features/vehicleSlice';
import VideoWall from './VideoWall';
import _ from 'lodash';
import MapOsmCamera from './MapOsmCamera';


const MapViewCamera = () => {
 
  const dispatch = useDispatch();
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const agencyID = isAgency ? dataLogin.agency.id : undefined;

  useEffect(() => {
    dispatch(GetUserInfo());
  }, []);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getListVehicleTracking(''));
      var i3 = window.setInterval(() => {
        dispatch(getListVehicleTracking(''));
      }, 5000);
    }else{
      dispatch(getListVehicleTracking(`/agency/${agencyID}`));
      var i4 = window.setInterval(() => {
        dispatch(getListVehicleTracking(`/agency/${agencyID}`));
      }, 5000);
    }
    return () => {
      window.clearInterval(i3);
      window.clearInterval(i4);
    };
  }, [isAdmin, isAgency]);

  const query = new URLSearchParams(useLocation().search);

  let deviceSerial = query.get('device_serial')
    ? query.get('device_serial')
    : null;

  const listVehicleTracking = useSelector(
    state => state.vehicleSlice.listVehicleTracking
  );
  const vehiclesCamera4GTracking = listVehicleTracking.filter(vehicle => vehicle?.device_type === 'VGPS_CAM4G');
  const statusGetAll = useSelector(state => state.vehicleSlice.statusGetAll);
  const [center, setCenter] = useState({});
  const [zoom, setZoom] = useState(13);

  const [dataDriver, setDataDriver] = useState([]);
  const [mapRef, setMapRef] = useState(null);

  const showMenu = JSON.parse(query.get('show_menu'));

  useEffect(() => {
    if (deviceSerial) {
      const vehicle = findVehicle(deviceSerial);
      getVehicleViewMap(vehicle);
    }
  }, [deviceSerial]);

  useEffect(() => {
    if (mapRef) {
      setTimeout(() => {
        mapRef.invalidateSize();
      }, 300);
    }
  }, [showMenu, mapRef]);

  const findVehicle = deviceSerial => {
    const device = listVehicleTracking.filter(vehicle => {
      return vehicle.device_serial === deviceSerial;
    });

    return device.length > 0 ? device[0] : null;
  };

  const getSetView = mapRef => {
    if (mapRef) {
      setMapRef(mapRef);
    }
  };

  const getVehicleViewMap = vehicle => {
    if (vehicle && vehicle?.lat && vehicle?.lng) {
      setCenter([Number(vehicle.lat), Number(vehicle.lng)]);
      if (mapRef) return;
      mapRef?.setView([Number(vehicle.lat), Number(vehicle.lng)], 15);
    }
  };

  const getDriverInfo = vehicle => {
    const dataDriverClone = _.cloneDeep(dataDriver);
    let isExitIndex = dataDriverClone.findIndex(
      driver => driver.device_serial === vehicle.device_serial
    );
    if (isExitIndex > -1) {
      dataDriverClone[isExitIndex] = vehicle;
      setDataDriver(dataDriverClone);
    } else {
      setDataDriver([...dataDriverClone, vehicle]);
    }
  };

  const listVehicles = listVehicleTracking.filter(vehicle => {
    return vehicle.device_serial === query.get('device_serial');
  });

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent:'flex-end',
        overflowX: 'hidden',
        height: 'calc(100vh - 66px)'
      }}
    >
      <Box className="row">
        <Box
          className = {showMenu ? "col-9": "col-7"}
          style={{
            paddingLeft: 52,
          }}
        >
          <VideoWall deviceSerial={deviceSerial} key={deviceSerial} />
        </Box>
        <Box
          className = {showMenu ? "col-3": "col-5"}
          style={{
            paddingLeft: 2,
          }}
        >
          <MapOsmCamera
            center={center}
            zoom={zoom}
            getSetView={getSetView}
            listVehicleTracking={query.get('device_serial') ? listVehicles : vehiclesCamera4GTracking}
            loadingElement={<Loading />}
          />
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({

}));

export default React.memo(MapViewCamera);
