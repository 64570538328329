import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfoVehiceBox from 'src/app/components/maps/InfoVehicleBox';
import MenuBox from 'src/app/components/maps/MenuBox';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import { renderIconCar1 } from 'src/app/utils/mapService';
import { getDetailDevicePosition } from 'src/features/deviceSlice';
import _size from 'lodash/size';
// import './style.css';
import {
  Button,
  Divider,
  makeStyles,
  Typography,
  Box
} from '@material-ui/core';
import 'leaflet-rotatedmarker';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  ZoomControl,
  Tooltip,
  GeoJSON,
  LayersControl
} from 'react-leaflet';
import L from 'leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import clsx from 'clsx';
import mkIcon from '../../assets/mkicon.png';
import { getIconStatus } from 'src/app/utils/vehicleService';
import { useLocation, useSearchParams } from 'react-router-dom';
import LicensePlateTag from 'src/app/components/licensePlateTag/LicesePlateTag';
import ContentCarInfo from 'src/app/components/popupInfocar/ContentCarInfo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const KeepMarkerInView = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (!position) return;

    const bounds = map.getBounds();
    if (!bounds.contains(position)) {
      map.panTo(position, { animate: true }); 
    }
  }, [position, map]);

  return null;
};


const MapOsm2 = ({ listVehicle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location.pathname;

  const statusGetAll = useSelector(state => state.vehicleSlice.statusGetAll);
  const positionsDevice = useSelector(
    state => state.deviceSlice.positionsDevice
  );
  const centerSystem = useSelector(state => state.vehicleSlice.centerMap);
  const [positionsInfoBox, setPositionsInfoBox] = useState({});
  const [position, setPosition] = useState([]);
  const [follow, setFollow] = useState(false);
  const [center, setCenter] = useState([21.0278, 105.8342]);
  const [mode, setMode] = useState('');
  const [line, setLine] = useState([]);
  const [snapped, setSnapped] = useState([]);
  const [showMenu, setShowMenu] = useState(true);
  const [endPoint, setEndPoint] = useState();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [speed, setSpeed] = useState(100);
  const [carIdSelected, setCarIdSelected] = useState(0);
  const [startPoint, setStartPoint] = useState();
  const [positionNext, setPositionNext] = useState();
  const [originalPoints, setOriginalPoints] = useState([]);
  const [timer, setTimer] = useState(null);
  const [original, setOriginal] = useState();
  const markerRef = useRef();
  //osm
  const [mapRef, setMapRef] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (centerSystem) setCenter(centerSystem);
  }, []);

  // useEffect(() => {
  //   const marker = markerRef.current;
  //   if (marker) {
  //     marker.openPopup();
  //   }
  // }, [markerRef.current]);

  //chay khi follow =true
  const getPosCarNext = counter => {
    if (counter < snapped.length) {
      var timer = setTimeout(function() {
        counter++;
        if (snapped[counter]) {
          setPosition(snapped[counter]);
          setPositionNext(snapped[counter + 1]);
        }
        setOriginal(snapped[counter]);
        getPosCarNext(counter);
      }, speed);

      setTimer(timer);
    }
  };

  // chua chay lan 1
  useEffect(() => {
    if (follow) {
      if (original === undefined) {
        return;
      } else {
        const nline = line.concat(original);
        setLine(nline);
        if (original?.originalIndex !== null) {
          const oPoint = originalPoints.concat(original);
          setOriginalPoints(oPoint);
        }
      }
    }
  }, [original]);

  // done
  useEffect(() => {
    if (mode === 'all') {
      clearTimeout(timer);
    }
  }, [mode]);

  // click tai lo trinh
  const loadSnapApi = posDevice => {
    const newSnap = posDevice?.map(value => {
      return {
        lat: value?.location.latitude,
        lng: value?.location.longitude,
        originalIndex:
          value && value.originalIndex ? value.originalIndex : null,
        created_at: value?.created_at ?? null,
        speed: value?.speedDigitalMeter ?? 0,
        airConditionStatus: value?.airConditionStatus,
        battery: value?.battery,
        chargeStatus: value?.chargeStatus,
        doorStatus: value?.doorStatus,
        engineStatus: value?.engineStatus,
        speedGps: value?.speedGps,
        placeId: value?.placeId,
        olat: value?.lat,
        olng: value?.lng
      };
    });
    setSnapped(newSnap);
    const arrNotNull = posDevice.filter(el => {
      return el != null;
    });
    const sPoint = [
      arrNotNull[0]?.location.latitude,
      arrNotNull[0]?.location.longitude
    ];
    const ePoint = [
      arrNotNull[arrNotNull.length - 1]?.location.latitude,
      arrNotNull[arrNotNull.length - 1]?.location.longitude
    ];
    setCenter(sPoint);
    setStartPoint(sPoint);
    setEndPoint(ePoint);
  };

  //done
  const startFollow = () => {
    if (snapped.length > 0) {
      setFollow(true);
    }
  };

  //chay khi follow = true
  useEffect(() => {
    if (follow) {
      getPosCarNext(0);
    } else {
      setLine([]);
    }
  }, [follow]);

  //chay khi follow = true
  useEffect(() => {
    if (follow) {
      const angle = getDegree(position, positionNext);
      if (markerRef.current) {
        markerRef.current.setRotationAngle(angle);
        markerRef.current.setRotationOrigin('center');
      }
    }
  }, [positionNext, follow]);

  //done
  const resetTracking = () => {
    setFollow(false);
    setLine([]);
    setOriginalPoints([]);
    setPosition(snapped[0]);
    setPositionNext(null);
    setSnapped([]);
    setStartPoint(null);
    clearTimeout(timer);
  };

  // click tai lo trinh
  const handleRoadTrackingUpdate = async ({ id, first_time, last_time }) => {
    setLine([]);
    setMode('lo_trinh');
    dispatch(
      getDetailDevicePosition({
        id: id,
        first_time: first_time,
        last_time: last_time
      })
    );
    if (!carIdSelected || carIdSelected !== id) {
      clearTimeout(timer);
    } else {
      setPosition(snapped[0]);
    }

    if (!mapRef) return;
    mapRef.flyTo(center, 15, {
      animate: true
    });
    setCarIdSelected(id);
  };

  // click tai lo trinh
  useEffect(() => {
    if (positionsDevice && positionsDevice.length > 0) {
      setMode('lo_trinh');
      loadSnapApi(positionsDevice);
    } else {
      setMode('all');
    }
  }, [positionsDevice]);

  useEffect(() => {
    resetTracking();
  }, [pathName]);

  const handleMarkerClick = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      setPositionsInfoBox({
        license_plate: vehicle.license_plate,
        lat: Number(vehicle.lat),
        lng: Number(vehicle.lng),
        created_at: vehicle.created_at,
        air_condition_status: vehicle.air_condition_status,
        battery: vehicle.battery,
        charge_status: vehicle.charge_status,
        speed_gps: vehicle.speed_gps,
        door_status: vehicle.door_status
      });
      setShowInfoWindow(true);
    }
  };

  const handleCloseInfo = () => {
    setShowInfoWindow(false);
    setPositionsInfoBox({});
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCenter([position.coords.latitude, position.coords.longitude]);
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude
        ]);

        if (!mapRef) return;
        mapRef.flyTo(
          [position.coords.latitude, position.coords.longitude],
          13,
          {
            animate: true
          }
        );
      });
    }
  };

  const getVehicle = (rowTableCurrent, rowTableNext) => {
    if (!rowTableCurrent || !rowTableNext) return;
    handleMarkerClick(rowTableCurrent);
    const angle = getDegree(rowTableCurrent, rowTableNext);
    if (markerRef.current) {
      markerRef.current.setRotationAngle(angle);
      markerRef.current.setRotationOrigin('center');
    }
    setPosition(rowTableCurrent);
  };

  const getDegree = (pos1, pos2) => {
    if (
      Number(pos1?.lat) != Number(pos2?.lat) ||
      Number(pos1?.lng) != Number(pos2?.lng)
    ) {
      const point1LatLng = {
        lat: Number(pos1?.lat),
        lng: Number(pos1?.lng)
      };

      const point2LatLng = {
        lat: Number(pos2?.lat),
        lng: Number(pos2?.lng)
      };

      const angle =
        (Math.atan2(
          point2LatLng?.lng - point1LatLng?.lng,
          point2LatLng?.lat - point1LatLng?.lat
        ) *
          180) /
        Math.PI;
      const actualAngle = angle;
      return actualAngle;
    }
  };

  const handleDisplayIconFlag = useMemo(() => {
    const iconCache = {};

    return statusIcon => {
      if (iconCache[statusIcon]) {
        return iconCache[statusIcon];
      }

      const url = renderIconCar1(statusIcon);

      const makerIcon = new L.icon({
        iconUrl: url,
        iconSize: [20, 40],
        iconAnchor: [0, 40],
        popupAnchor: [0, -46]
      });

      iconCache[statusIcon] = makerIcon;
      return makerIcon;
    };
  }, [renderIconCar1]);

  const renderStartPoint = () =>
    startPoint ? (
      <>
        <Marker
          key={1}
          zIndexOffset={3}
          position={startPoint}
          icon={handleDisplayIconFlag('begin')}
        />
        <Marker
          key={2}
          zIndexOffset={3}
          position={endPoint}
          icon={handleDisplayIconFlag('begin')}
        />
      </>
    ) : null;

  const displayIcon1 = () => {
    const url = renderIconCar1(position ? position.status : '');
    let makerIcon = new L.icon({
      iconUrl: url,
      iconAnchor: [16, 16]
    });
    return makerIcon;
  };

  const displayLocationIcon = () => {
    let locationCurrent;
    locationCurrent = L.icon({
      iconUrl: `/static/iconSvg/gps.svg`,
      iconSize: [25, 25],
      iconAnchor: [0, 25]
    });
    return locationCurrent;
  };

  //#region Display Icons
  const handleDisplayVehicleIcon = vehicle => {
    let icon;
    icon = L.icon({
      iconUrl: getIconStatus(vehicle.device_status, vehicle.device_type)
      // iconAnchor: [16, 16]
    });
    return icon;
  };

  //console.log('position', position);

  return (
    <>
      <Box className="show_menu" sizeSmall onClick={() => setShowMenu(true)}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 0.3s ease-in'
          }}
        >
          <ArrowRight />
        </Box>
      </Box>

      <Box
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: 22,
          left: 385,
          zIndex: 999,
          backgroundColor: '#ffffff',
          borderRadius: '0 8px 8px 0',
          boxShadow: 'rgb(220,220,220) 3px 3px 6px 0px inset',
          width: '28px',
          height: '48px',
          display: showMenu ? 'block' : 'none',
          transition: 'all .3s'
        }}
      >
        <Box
          title="Ẩn Menu"
          onClick={() => setShowMenu(false)}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ArrowLeft />
        </Box>
      </Box>

      <Box
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',

          cursor: 'pointer',
          boxShadow: 'rgba(20, 20, 20, 0.322)  0px 0px 2px 0px',
          border: '0px !important',

          padding: '8px',
          color: 'red',
          width: '45px',
          height: '45px',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          backgroundColor: 'white',
          borderRadius: '50%',
          zIndex: 1000
        }}
        onClick={() => getCurrentLocation()}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src="/static/iconSvg/locationIconv2.svg" />
        </Box>
      </Box>

      <MenuBox
        handleRoadTrackingUpdate={handleRoadTrackingUpdate}
        getVehicle={getVehicle}
        startFollow={startFollow}
        resetTracking={resetTracking}
        stoppedPoint={originalPoints}
        follow={follow}
        statusGetAll={statusGetAll}
        listVehicle={listVehicle}
        setShowMenu={setShowMenu}
        showMenu={showMenu}
        mode={mode}
        setMode={setMode}
        snapped={snapped}
      />

      <MapContainer
        center={center}
        zoom={13}
        maxZoom={18}
        minZoom={6}
        style={{ height: 'calc(100vh - 66px)', position: 'relative' }}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={mapR => setMapRef(mapR)}
        zoomAnimation={true}
      >
        <LayersControl>
          <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Vệ tinh">
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer checked name="Google Map">
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <FullscreenControl forceSeparateButton={true} position="topright" />
        <ZoomControl position="topright" style={{ marginRight: '15px' }} />

        {mode === 'lo_trinh' && follow ? (
          <>
            <GeoJSON
              style={{
                color: '#c72222'
              }}
              data={{
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: snapped.map((lineItem, index) => {
                    return [lineItem.lng, lineItem.lat];
                  })
                }
              }}
            />

            {position?.lat && position?.lng && (
              <>
                <Marker
                  // eventHandlers={{
                  //   click: () => handleMarkerClick(position)
                  // }}
                  position={[Number(position?.lat), Number(position?.lng)]}
                  ref={markerRef}
                  icon={displayIcon1()}
                  zIndexOffset={5}
                >
                  {positionsInfoBox && (
                    <Popup
                      position={[positionsInfoBox.lat, positionsInfoBox.lng]}
                      autoPanPaddingTopLeft={[20, 40]}
                      keepInView={true}
                      onClose={() => setPositionsInfoBox(null)}
                    >
                      <ContentCarInfo positionsInfoBox={positionsInfoBox} />
                    </Popup>
                  )}
                    <KeepMarkerInView position={[Number(position?.lat), Number(position?.lng)]} />
                </Marker>
              </>
            )}
          </>
        ) : (
          ''
        )}

        {mode === 'lo_trinh' ? renderStartPoint() : ''}

        {mode === 'all' && listVehicle && listVehicle.length > 0
          ? listVehicle.map((vehicle, index) => (
              <>
                {vehicle && vehicle.lat && vehicle.lng ? (
                  <Marker
                    key={index}
                    options={{
                      rotation: 50
                    }}
                    position={[Number(vehicle.lat), Number(vehicle.lng)]}
                    eventHandlers={{
                      click: () => handleMarkerClick(vehicle)
                    }}
                    icon={handleDisplayVehicleIcon(vehicle)}
                  >
                    {showInfoWindow && positionsInfoBox ? (
                      <Popup
                        interactive={true}
                        onClose={handleCloseInfo}
                        position={[positionsInfoBox.lat, positionsInfoBox.lng]}
                        eventHandlers={{
                          remove: handleCloseInfo
                        }}
                        keepInView={true}
                        autoPanPaddingTopLeft={[20, 40]}
                      >
                        <ContentCarInfo positionsInfoBox={positionsInfoBox} />
                      </Popup>
                    ) : null}

                    <LicensePlateTag
                      position={vehicle}
                      license_plate={vehicle.license_plate}
                    />
                  </Marker>
                ) : null}
              </>
            ))
          : null}
        {currentLocation && (
          <Marker icon={displayLocationIcon()} position={currentLocation}>
            <Tooltip
              style={{
                background: 'rgba(0, 0, 0, 0) !important',
                boxShadow: 'none !important'
              }}
              position={currentLocation}
              direction="center"
              permanent
            >
              <Box
                style={{
                  backgroundColor: 'rgba(19, 19, 19, 0.493)',
                  color: 'white',
                  borderRadius: '5px',
                  padding: '2px',
                  position: 'absolute',
                  top: 5,
                  left: -22
                }}
              >
                Vị trí của bạn
              </Box>
            </Tooltip>
          </Marker>
        )}
      </MapContainer>
    </>
  );
};

export default React.memo(MapOsm2);
