import React from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  AppBar,
  Tabs,
  Tab,
  Slide
} from '@material-ui/core';
import './style.css';

import {
  STATUS_API,
  CAR_STATUS,
  PAGE_SIZE_LIST
} from 'src/app/constant/config';
import { useState, useEffect } from 'react';
import MenuVideo from 'src/app/components/camera/MenuVideo';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { GetUserInfo } from 'src/features/authSlice';
import { useLocation, useNavigate } from 'react-router';
import {
  getListVehicle,
  getListVehicleTracking,
  getListVehicleTrackingAdmin
} from 'src/features/vehicleSlice';
import PlaybackMenu from '../../playback/PlaybackMenu';
import { ButtonHideMenu } from 'src/app/components/button/ButtonMenu';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const CustomTab = withStyles({
  root: {
    backgroundColor: '#F6F6F6',
    color: '#475461',
    fontSize: '14px'
  },
  selected: {
    backgroundColor: '#ffffff',
    color: '#C62222',
    fontWeight: 600,
    fontSize: '14px'
  }
})(Tab);

const MenuControl = ({ showMenu, setShowMenu }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [center, setCenter] = useState({});
  const [zoom, setZoom] = useState(13);
  const [dataDriver, setDataDriver] = useState([]);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const statusGetAll = useSelector(state => state.vehicleSlice.statusGetAll);
  const listVehicle = useSelector(state => state.vehicleSlice.listVehicle);
  const vehiclesCamera4G = listVehicle.filter(vehicle => vehicle.device_type?.name === 'VGPS_CAM4G');

  useEffect(() => {
    dispatch(GetUserInfo());
  }, []);

  useEffect(() => {
    dispatch(getListVehicle({page: 1, pageSize: 20}));
  }, []);

  const query = new URLSearchParams(useLocation().search);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);

  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const agencyID = isAgency ? dataLogin.agency.id : undefined;

  const deviceSerial = query.get('device_serial')
    ? query.get('device_serial')
    : undefined;

  useEffect(() => {
    if (deviceSerial) {
      const vehicle = findVehicle(query.get('device_serial'));
      getVehicle(vehicle);
    }
  }, [listVehicle]);

  const findVehicle = deviceSerial => {
    const device = listVehicle.filter(vehicle => {
      return vehicle.device_serial === deviceSerial;
    });
    return device.length > 0 ? device[0] : null;
  };

  const getVehicle = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      localStorage.setItem('center', `(${vehicle.lat}, ${vehicle.lng})`);
      setCenter({ lat: Number(vehicle.lat), lng: Number(vehicle.lng) }, 15);
      setZoom(15);
    }
  };

  const getDriverInfo = vehicle => {
    const dataDriverClone = _.cloneDeep(dataDriver);
    let isExitIndex = dataDriverClone.findIndex(
      driver => driver.device_serial === vehicle.device_serial
    );
    if (isExitIndex > -1) {
      dataDriverClone[isExitIndex] = vehicle;
      setDataDriver(dataDriverClone);
    } else {
      setDataDriver([...dataDriverClone, vehicle]);
    }
  };

  return (
    <Slide
      direction="right"
      in={showMenu}
      style={{
        height: '100%',
        borderRadius: '8px',
        boxShadow: '5px 5px 8px 0px rgba(199, 199, 199, 0.87)',
        zIndex: 12,
        position: showMenu ? 'unset' : 'absolute',
      }}
    >
      <Card
        style={{
          width: '384px',
          height: 'calc(100vh - 66px)'
        }}
      >
        
        <ButtonHideMenu 
            showMenu={showMenu}
            setShowMenu={setShowMenu} 
        />

        <div className={classes.root}>
          <AppBar
            position="static"
            style={{
              backgroundColor: '#ffffff',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              centered
              aria-label="simple tabs example"
              style={{
                height: '52px',
                width: '100%'
              }}
              TabIndicatorProps={{ hidden: true }}
            >
              <CustomTab
                label="Giám sát trực tiếp"
                {...a11yProps(0)}
                style={{
                  textTransform: 'none',
                  height: '100%'
                }}
                onClick={() => navigate('/app/camera/streaming?show_menu=true')}
                //className= {value ===0 ? classes.active_tab :classes.default_tabStyle}
              />
              <CustomTab
                label="Phát lại video"
                {...a11yProps(1)}
                style={{
                  textTransform: 'none',
                  height: '100%'
                }}
                onClick={() => navigate('/app/camera/playback')}
                //className= {value === 1 ? classes.active_tab :classes.default_tabStyle}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <MenuVideo
              statusGetAll={statusGetAll}
              getVehicle={getVehicle}
              listVehicle={vehiclesCamera4G}
              getDriverInfo={getDriverInfo}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PlaybackMenu
              statusGetAll={statusGetAll}
              listVehicle={vehiclesCamera4G}
              getDriverInfo={getDriverInfo}
            />
          </TabPanel>
        </div>
      </Card>
    
    </Slide>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
    // backgroundColor:'red'
  },
  typography: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100vh'
  },
  listInformation: {
    width: '100%',
    maxWidth: 375,
    borderRadius: 8,
    maxHeight: 469,
    backgroundColor: theme.palette.background.paper
  },
  popoverHeader: {
    textAlign: 'center'
  },
  textInforCar: {
    color: '#0C1132 !important',
    fontSize: '14px !important',
    lineHeight: '19.6px',
    fontWeight: 400,
    padding: '15px 0'
  },

  default_tabStyle: {
    color: '#475461',
    fontSize: '14px'
  },

  active_tabStyle: {
    fontSize: '14px',
    color: '#C62222',
    fontWeight: 600
  }
}));

export default MenuControl;
