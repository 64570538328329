import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { MenuOpen as MenuOpenIcon } from '@material-ui/icons';
import {
  MenuItem,
  TextField,
  Paper,
  Box,
  Select,
  makeStyles,
  withStyles,
  SvgIcon,
  InputAdornment
} from '@material-ui/core';
import { CAR_STATUS } from 'src/app/constant/config';

import {
  getListVehicle,
  getVehicleLicensePlateByAdmin
} from 'src/features/vehicleSlice';

import { getListDeviceType } from 'src/features/deviceTypeSlice';
import { getListAgencies } from 'src/features/agencySlice';
import { roles } from 'src/app/constant/roles';
import { STATUS_API } from 'src/app/constant/config';
import { useSearchParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    width: 365,
    boxShadow: 'none',
    padding: '0 16px 0 0'
  },
  input: {
    flex: 1,
    fontSize:'10px'
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  titleLotrinh: {
    fontSize: '18px',
    color: '#C62222',
    fontWeight: 700,
    marginBottom: '10px'
  },
  titleTime: {
    fontSize: '18px',
    color: '#0C1132',
    fontWeight: 700,
    marginBottom: '10px'
  },
  formControl: {
    height: '60px',
    marginTop: '10px',
    width: '360px',
    marginBottom: '10px'
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectDropdown: {
    padding: '5 5 5 5 '
  },
  dropDown: {
    width: '98%'
  },

  option: {
    fontSize: "14px"
  }
}));

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D6D9DB',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D6D9DB',
      },
      '&:hover fieldset': {
        borderColor: '#C62222',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#C62222',
      },
    },
  },
})(TextField);

export default function ToolBar({ setShowMenu, setDeviceType, deviceType, agencySelected, setAgencySelected }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState();

  const listDeviceType = useSelector(
    state => state.deviceTypeSlice.listDeviceType
  );
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const listLicensePlate = useSelector(
    state => state.vehicleSlice.listLicensePlate
  );

  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  useEffect(() => {
    if(isAdmin)
    dispatch(getVehicleLicensePlateByAdmin());
  }, [isAdmin]);

    // Get list device type
    useEffect(() => {
      dispatch(getListDeviceType());
    }, []);
  
    useEffect(() => {
      if(isAdmin) dispatch(getListAgencies());
    }, [isAdmin]);
  
  const handleSearchDevice = e => {
    const newValue = e.target.value;
    setValue(newValue);
    if (isAdmin) {
      dispatch(getListVehicle({ license_plate: newValue}));
    } else if (isAgency) {
      dispatch(
        getListVehicle({ license_plate: newValue, agency_id: agencyID })
      );
    }
  };

  const handleChangeInput = (e, value) => {
    e.preventDefault();
    const newValue = e.target.value;
    setValue(newValue);
  };

  const keyPress = (e, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const newValue = e.target.value;
      setValue(newValue);
      if (isAdmin) {
        dispatch(getListVehicle({ license_plate: newValue }));
      } else if (isAgency) {
        dispatch(
          getListVehicle({ license_plate: newValue, agency_id: agencyID })
        );
      }
    }
  };

  const handleChangeAgency = e => {
    setAgencySelected(e.target.value)
    dispatch(getListVehicle({agency_id: e.target.value}));
  };

  console.log('listLicensePlate ===>', listLicensePlate);

  return (
      <Paper 
          component="form" 
          className={classes.root}
          style={{marginTop: 0}}
      >
          <Box
            style={{
              borderRadius: '4px',
              marginBottom: '8px',
            
            }}
          >
            {listLicensePlate && (
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                size="small"
                disableClearable
                options={listLicensePlate}
                value={value ? value : ''}
                classes={{ option: classes.option }}
                renderInput={params => (
                  <CssTextField
                    {...params}
                    margin="dense"
                    className="input-no-fieldset"
                    variant="outlined"
                    placeholder="Tìm kiếm thiết bị"
                    onChange={e => handleChangeInput(e, value)}
                    onKeyDown={keyPress}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: '14px',
                      },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ 
                            cursor: 'pointer',
                          }}
                        >
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon onClick={e => handleSearchDevice(e)} />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            )}
          </Box>

          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column ',
                width: '48%',
                borderColor: '#E5E5E8',
                borderWidth: '1px',
                borderStyle: 'solid',
                padding: '0.2em',
                borderRadius: '5px'
              }}
            >
              <Select
                style={{ width: '100%' }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={deviceType || ''}
                onChange={e => setDeviceType(e.target.value)}
                displayEmpty
                disableUnderline
              >
                <MenuItem value="">
                  <small> Tất cả thiết bị </small>
                </MenuItem>
                {listDeviceType &&
                  listDeviceType.map(deviceType => (
                    <MenuItem value={deviceType.name}>
                      <small>{deviceType.name}</small>
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column ',
                width: '48%',
                borderColor: '#E5E5E8',
                borderWidth: '1px',
                borderStyle: 'solid',
                padding: '0.2em',
                borderRadius: '5px'
              }}
            >
              <Select
                style={{ width: '100%' }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                defaultValue={''}
                value={agencySelected || ''}
                onChange={e => handleChangeAgency(e)}
                displayEmpty
                disableUnderline
              >
                <MenuItem value="" >
                  <small> Chọn đại lý </small>
                </MenuItem>
                {listAgencies &&
                  listAgencies.map(agency => (
                    <MenuItem value={agency.id}>
                      <small>{agency.name}</small>
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
      </Paper>
  );
}
