import { createSlice } from '@reduxjs/toolkit';

export const videoSlice = createSlice({
  name: 'videoSlice',
  initialState: {
    isShowToast: false,
    isShowLoading: false

  },
  reducers: {
  
    showToast: (state) => {
      state.isShowToast = true;
    },
    closeToast: (state) => {
      state.isShowToast = false
    },
    showLoading: (state) => {
      state.isShowLoading = true
    },
    closeLoading: (state) => {
      state.isShowLoading = false
    },

    resetVideoSlice: (state) => {
        state.isShowToast = false;
        state.isShowLoading = false;
    }
  }

});
export const {
    showToast, closeToast, showLoading, closeLoading, resetVideoSlice
} = videoSlice.actions;


export default videoSlice.reducer;
