import React, { useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Card,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Divider
} from '@material-ui/core';
import ToolBarTracking from 'src/app/components/ToolBarTracking';
import VehicleTable from 'src/app/components/tables/VehicleTable';
import CarStatusBar from './CarStatusBar';

import { STATUS_API, CAR_STATUS } from 'src/app/constant/config';
import { getListVehicle } from 'src/features/vehicleSlice';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    width: '375px',
    height: '469px'
  },
  listInformation: {
    width: '100%',
    maxWidth: 375,
    borderRadius: 8,
    maxHeight: 469,
    backgroundColor: theme.palette.background.paper
  },
  popoverHeader: {
    textAlign: 'center'
  },
  textInforCar: {
    color: '#0C1132 !important',
    fontSize: '14px !important',
    lineHeight: '19.6px',
    fontWeight: 400,
    padding: '15px 0'
  }
}));

const MenuBox = ({
  getVehicle,
  statusGetAll,
  setShowMenu,
  showMenu,
  setCarStatus,
  carStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listVehicle = useSelector(state => state.vehicleSlice.listVehicle);
  const totalVehicle = useSelector(state => state.vehicleSlice.totalVehicle);

  const statisticVehicleTracking = useSelector(state => state.vehicleSlice.statisticVehicleTracking);
  const listVehicleTracking = useSelector(state => state.vehicleSlice.listVehicleTracking);
  
  const [currentCount, setCurrentCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deviceType, setDeviceType] = React.useState('');
  const [agencySelected, setAgencySelected] =  React.useState(0);

  useEffect(() => {
    if(carStatus){
      const listVehicleStatus = listVehicleTracking.filter(vehicle => vehicle.device_status === carStatus); 
      setCurrentCount(listVehicleStatus.length);
    }else{
      setCurrentCount(totalVehicle);
    }
  }, [totalVehicle, carStatus]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setCarStatus('');
    setDeviceType('');
    setAgencySelected('');
    dispatch(getListVehicle({page: 1, pageSize: 10}));
  }

  const open = Boolean(anchorEl);
  const iconInfo = open ? 'notice' : 'notice_gray';
  const id = open ? 'simple-popover' : undefined;

  return (
    <Slide
      direction="right"
      in={showMenu}
      style={{
        height: '100%',
        bottom: 1,
        left: 1,
        borderRadius: '8px',
        boxShadow: '5px 5px 15px 0px #9e9e9ede',
        position: 'absolute',
        zIndex: 450
      }}
    >
      <Card
        style={{
          paddingTop: '50px',
          padding: '1em',
          height: 'calc(100vh - 66px)',
          backgroundColor: '#d7dae6de !important',
          width: '384px',
          overflowY: 'auto',
          position: 'absolute',
          top: 0,
          left: 0,
          overflowY:'hidden'
        }}
      >
        <ToolBarTracking
          setShowMenu={setShowMenu}
          setCarStatus={setCarStatus}
          setDeviceType={setDeviceType}
          deviceType={deviceType}
          carStatus={carStatus}
          agencySelected = {agencySelected}
          setAgencySelected = {setAgencySelected}
        />
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', marginTop:'14px', marginBottom:'0px' }}>
              <Typography style={{color:'#0C1132', font:'16px', fontWeight: 600}}>
                Danh sách xe 
              </Typography>
              <Box style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>

                <Typography style={{paddingRight:'8px', color:'rgb(173, 0, 0)', fontSize:'14px', fontWeight:'500'}}>
                    Tổng số xe: {currentCount}/{listVehicleTracking?.length}
                </Typography>

                <Box 
                  style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}
                >
                    <span 
                      className="list_tracking_action"
                      onClick={handleReset}
                    >
                      <img alt="icon" src="/static/iconSvg/sync.svg" />{' '}
                    </span>
                    <span
                      className="list_tracking_action"
                      aria-describedby={id}
                      onClick={handleClick}
                    >
                      <img alt="icon" src={`/static/iconSvg/${iconInfo}.svg`} />
                    </span>
                </Box>

                <Box style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  >
                    <Typography className={classes.typography}>
                      <div className={classes.popoverHeader}>
                        <b> Chú thích </b>
                        <span className="float-right">
                          <img
                            alt="button_close"
                            className="list_tracking_action"
                            onClick={handleClose}
                            width="12px"
                            height="12px"
                            src="/static/iconSvg/close-btn.svg"
                          />
                        </span>
                      </div>

                      <List className={classes.listInformation}>
                        {CAR_STATUS &&
                          CAR_STATUS.map((car, index) => (
                            <>
                              <ListItem>
                                <ListItemAvatar>
                                  <img
                                    alt="icon"
                                    src={`/static/iconSvg/cars/${car?.icon}.svg`}
                                    className="imageCarInfo"
                                  />
                                </ListItemAvatar>
                                <span className={classes.textInforCar}>
                                  {car?.info}
                                </span>
                              </ListItem>
                              {CAR_STATUS.length !== index + 1 ? (
                                <Divider variant="inset" component="li" />
                              ) : null}
                            </>
                          ))}
                      </List>
                    </Typography>
                  </Popover>
                </Box>
              </Box>
            </Box>

        <VehicleTable
          vehicles={listVehicleTracking}
          getVehicle={getVehicle}
          isLoading={statusGetAll === STATUS_API.PENDING}
          carStatus={carStatus}
          deviceType={deviceType}
        />

        <Box style={{ 
          bottom: 20, 
          position: 'absolute', 
          width: '90%' 
          }}>
          <CarStatusBar
            carStatus={carStatus}
            setCarStatus={setCarStatus}
            statisticVehicleTracking={statisticVehicleTracking}
          />
        </Box>
      </Card>
    </Slide>
  );
};

export default React.memo(MenuBox);
