import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'date-fns';
import {
  Box,
  Card,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ToolBar from 'src/app/components/ToolBarPlayback';
import VehicleRoadMapTable from 'src/app/components/tables/VehicleRoadMapTable';

import { STATUS_API } from 'src/app/constant/config';

import {
  Slider,
  Popover,
  Typography,
  List,
  ListItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    width: '304px',
    height: '408px'
  },
  listInformation: {
    width: '100%',
    maxWidth: 375,
    borderRadius: 8,
    maxHeight: 469,
    backgroundColor: theme.palette.background.paper
  },
  popoverHeader: {
    textAlign: 'left'
  }
}));

const PlaybackMenu = ({
  listVehicle
}) => {
  
  const classes = useStyles();
  const statusGetPositions = useSelector(state => state.deviceSlice.statusGetPositions);
  const waiting = statusGetPositions !== STATUS_API.SUCCESS && statusGetPositions !== null;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  return (
        <ToolBar
          listVehicle={listVehicle}
        />
  );
};

export default React.memo(PlaybackMenu);
