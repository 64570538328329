import React, { useState, useEffect } from 'react';
import { List, ListItem, Divider, Typography, Box } from '@material-ui/core';
import 'leaflet-rotatedmarker';
import { INFOR_CAR } from 'src/app/constant/config';
import moment from 'moment';
import './style.css';

const ContentCarInfo = (props) => {
  const { positionsInfoBox, vehicle } = props;

  const renderValueInWindowBox = infor => {
    switch (infor.dataKey) {
      case 'created_at':
        return moment()
          .utc(positionsInfoBox[infor.dataKey])
          .format('DD/MM HH:mm:ss');

      case 'speed_gps':
        return `${Math.floor(positionsInfoBox[infor.dataKey])} Km/h`;

      case 'engine_status':

      case 'air_condition_status':
        return positionsInfoBox[infor.dataKey] ? 'Bật' : 'Tắt';

      default:
        return positionsInfoBox[infor.dataKey] ?? '--';
    }
  };

  return (
    <Box
      style={{
        overflowY: 'scroll',
        width: '100%',
        height: 'fit-content',
        padding: '0px 10px 0px 10px',
        borderRadius: '7px',
        background: '#ffffff',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        position: 'absolute',
        top: 0,
        left: 0
      }}
    >
      <Typography
        color="primary"
        style={{
          color: '#C62222',
          fontSize: '20px',
          fontWeight: 600,
          paddingTop: '5px',
          margin: 0
        }}
      >
        Thông tin xe
      </Typography>
      <List
        component="nav"
        style={{
          padding: '0',
          width: '375px !important'
        }}
      >
        {INFOR_CAR.map((infor, index) => (
          <>
            <ListItem
              disableGutters
              style={{
                padding: '4px 0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ float: 'left' }}>
                <img
                  src={`/static/iconSvg/${infor.icon}.svg`}
                  style={{
                    paddingRight: '5px',
                    marginLeft: '0 !important'
                  }}
                />
                <b> {infor.label} </b>
              </div>

              <div
                style={{
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                {renderValueInWindowBox(infor)}
              </div>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Box>
  );
}
 
export default React.memo(ContentCarInfo);