import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography
} from '@material-ui/core';
import NavItem from './NavItem';
import { withStyles } from '@material-ui/core/styles';
import { ImageIcon } from 'src/app/utils/UltilsComponents';

import { styled } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 280,
    top: 46,
    height: 'calc(100% - 46px)',
    marginTop: '20px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  nested: {
    // paddingLeft: 40
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    color: '#45485E'
  },
  titleHeader: {
    width: '100%',
    // height: '45px',
    color: '#C62222',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    fontWeight: 'bold',
    fontSize: '18px',
    // marginBottom: '20px'
  }
}));

const NavBarContainer = styled(Box)({});

const menuTT073 = [
  {
    href: `/app/baocao-bgt/tt073/hanh-trinh-chay-xe`,
    title: 'Hành trình chạy xe',
    icon: <ImageIcon image={'/static/images/nav-hanhtrinhchayxe.svg'} />,
    icon_active: <ImageIcon image={'/static/images/nav-hanhtrinhchayxe.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt073/toc-do-cua-xe`,
    title: 'Tốc độ của xe',
    icon: <ImageIcon image={'/static/images/nav-tocdoxe.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt073/qua-toc-do-gioi-han`,
    title: 'Quá tốc độ giới hạn',
    icon: <ImageIcon image={'/static/images/nav-quatocdogioihan.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt073/thoi-gian-lai-xe-lien-tuc`,
    title: 'Thời gian lái xe liên tục',
    icon: <ImageIcon image={'/static/images/nav-thoigianlaixelientuc.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt073/bao-cao-tong-hop-theo-xe`,
    title: 'Báo cáo tổng hợp theo xe',
    icon: <ImageIcon image={'/static/images/nav-baocaotonghopxe.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt073/bao-cao-tong-hop-theo-lai-xe`,
    title: 'Báo cáo tổng hợp theo lái xe',
    icon: <ImageIcon image={'/static/images/nav-baocaotonghoplaixe.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt073/bao-cao-dung-do`,
    title: 'Báo cáo dừng đỗ',
    icon: <ImageIcon image={'/static/images/nav-baocaodungdo.svg'} />
  }
];

const menuTT09 = [
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-5`,
    title: 'Tổng hợp vi phạm',
    icon: <ImageIcon image={'/static/images/nav-baocaotonghopvipham.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-8`,
    title: 'Thống kê vi phạm về tốc độ',
    icon: <ImageIcon image={'/static/images/nav-quatocdogioihan.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-14`,
    title: 'Chi tiết vi phạm tốc độ xe chạy',
    icon: <ImageIcon image={'/static/images/nav-baocaoviphamtocdo.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-16`,
    title: 'Báo cáo lái xe quá 4h',
    icon: <ImageIcon image={'/static/images/nav-baocaolaixequa4h.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-17`,
    title: 'Báo cáo vi phạm thời gian lái xe liên tục trong ngày (10 giờ)',
    icon: <ImageIcon image={'/static/images/nav-thoigianlaixelientuc.svg'} />
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-19`,
    title: 'Chi tiết vi phạm truyền dữ liệu',
    icon: <ImageIcon image={'/static/images/nav-baocaotonghopxe.svg'} />
  }
];


// bc doanh nghiep

const bcTongHop = [
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-ve-tong-hop-hoat-dong-theo-nhom`,
    title: 'Báo cáo tổng hợp hoạt động (theo nhóm)'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-tong-hop-hoat-dong`,
    title: 'Báo cáo tổng hợp hoạt động'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-chi-tiet-hoat-dong`,
    title: 'Báo cáo chi tiết hoạt động (bao gồm mất tín hiệu)'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-lich-hen`,
    title: 'Báo cáo lịch hẹn'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-tong-hop-km-xe-hoat-dong`,
    title: 'Báo cáo tổng hợp km xe hoạt động'
  }
];


const bcHoatdong = [
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-tong-hop-bat-dieu-hoa`,
    title: 'Báo cáo tổng hợp bật điều hòa'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-dung-do`,
    title: 'Báo cáo dừng đỗ'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-ra-vao-tram`,
    title: 'Báo cáo ra vào trạm'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-chuyen-kinh-doanh`,
    title: 'Báo cáo chuyến kinh doanh'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-bat-dieu-hoa`,
    title: 'Báo cáo bật điều hòa'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-thu-phi-duong-bo`,
    title: 'Thu phí đường bộ - báo cáo phí theo chặng'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-cuoc-qua-diem-thu-phi`,
    title: 'Báo cáo cước qua điểm thu phí'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-hoat-dong-thang`,
    title: 'Báo cáo hoạt động tháng'
  }
]

const bcTrangThai = [
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-mo-cua`,
    title: 'Báo cáo mở cửa'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-nang-ha-ben`,
    title: 'Báo cáo nâng hạ ben'
  },
  {
    href: `/app/baocao-bgt/baocao-dn/bao-cao-nang-ha-cau`,
    title: 'Báo cáo nâng hạ cẩu'
  }
]


// makeStyles-button-1587 : dropdown button

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  // const location = useLocation();
  // const dataLogin = useSelector(state => state.authSlice.dataLogin);

  const content = (
    <NavBarContainer height="100%" display="flex" flexDirection="column">
   
        <List style={{padding: 0, marginTop: 2}}>
          <Typography className={classes.titleHeader}>
            Báo cáo Bộ GTVT
          </Typography>

          <NavItem
            title="Báo cáo TT073/2014/TT-BGTVT"
            arrayNested={menuTT073}
          />

          <NavItem 
            title="Báo cáo TT09/2014/TT-BGTVT" 
            arrayNested={menuTT09} 
          />
          
        </List>

        <List style={{padding: 0}}>
          <Typography className={classes.titleHeader}>
            Báo cáo Doanh nghiệp
          </Typography>
          <NavItem
            title="Báo cáo tổng hợp"
            arrayNested={bcTongHop}
            // icon={`/static/images/bcdn/baocao_dn_tonghop_theonhom.svg`}
          />
          <NavItem
            title="Báo cáo hoạt động"
            arrayNested={bcHoatdong}
            // icon={`/static/images/bcdn/baocao_dn_tonghop.svg`}
          />
          <NavItem
            title="Báo cáo hành trình"
            arrayNested={[
              {
                href: `/app/baocao-bgt/baocao-dn/bao-cao-hanh-trinh`,
                title: 'Báo cáo hành trình'
              }
            ]}
            //icon={`/static/images/nav-hanhtrinhchayxe.svg`}
          />
          <NavItem
            title="Báo cáo trạng thái"
            arrayNested={bcTrangThai}
            //icon={`/static/images/bcdn/baocao_dn_trangthai.svg`}
          />
          <NavItem
            title="Báo cáo động cơ"
            arrayNested={[
              {
                href: `/app/baocao-bgt/baocao-dn/bao-cao-dong-co`,
                title: 'Báo cáo động cơ'
              },
              {
                href: `/app/baocao-bgt/baocao-dn/bao-cao-trang-thai-dong-co`,
                title: 'Báo cáo trạng thái động cơ'
              }
            ]}
            //icon={`/static/images/bcdn/baocao_dn_dongco.svg`}
          />
          <NavItem
            title="Báo cáo hệ thống"
            arrayNested={[
              {
                href: `/app/baocao-bgt/baocao-dn/bao-cao-loi-xung`,
                title: 'Báo cáo lỗi xung'
              },
              {
                href: `/app/baocao-bgt/baocao-dn/bao-cao-mat-tin-hieu`,
                title: 'Báo cáo mất tín hiệu'
              }
            ]}
            //icon={`/static/images/bcdn/baocao_dn_hethong.svg`}
          />
        </List>
      <Box flexGrow={1} />
    </NavBarContainer>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
