import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, makeStyles, Button } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookie from 'js-cookie';
import MenuControl from './MenuControl';
import {
  ButtonShowMenu
} from 'src/app/components/button/ButtonMenu';


const MonitorView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Cookie.get('access-token')) navigate('/login');
  }, [navigate]);

  const [showMenu, setShowMenu] = React.useState(true);

  return (
    <div className={classes.root}>
      <ButtonShowMenu setShowMenu={setShowMenu} showMenu={showMenu}/>

      <MenuControl 
        showMenu={showMenu} 
        setShowMenu={setShowMenu} 
      />

      <Box
        style={{
          flex: 1,
          overflowY: 'scroll',
          zIndex: 10
        }}
      >
        <Outlet  
        />
      </Box>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(100vh - 66px)'
  }
}));

export default MonitorView;
