import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem,
  Box,
  Select,
  Paper,
  TextField,
  SvgIcon,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  getListVehicle,
  getVehicleLicensePlateByAdmin,
} from 'src/features/vehicleSlice';

import { CAR_STATUS, STATUS_API } from 'src/app/constant/config';
import { useSearchParams } from 'react-router-dom';
import { getListDeviceType } from 'src/features/deviceTypeSlice';
import { getListAgencies } from 'src/features/agencySlice';
import Autocomplete from '@material-ui/lab/Autocomplete';


const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D6D9DB',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D6D9DB',
      },
      '&:hover fieldset': {
        borderColor: '#C62222',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#C62222',
      },
    },
  },
})(TextField);

const ToolBar = ({ deviceType, setDeviceType}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const listDeviceType = useSelector(
    state => state.deviceTypeSlice.listDeviceType
  );
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);

  const dataLogin = useSelector(state => state.authSlice.dataLogin);

  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  const listLicensePlate = useSelector(
    state => state.vehicleSlice.listLicensePlate
  );

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    dispatch(getVehicleLicensePlateByAdmin());

    return () => {
      getListVehicle({ license_plate: '' });
    };
  }, []);

  const handleSearchVehicle = e => {
    const newValue = e.target.value;
    if (isAdmin) {
      dispatch(getListVehicle({ license_plate: newValue }));
    } else if (isAgency) {
      dispatch(
        getListVehicle({ license_plate: newValue, agency_id: agencyID })
      );
    }
  };

  useEffect(() => {
    dispatch(getListDeviceType());
    dispatch(getListAgencies());
  }, []);

  const handleChangeAgency = e => {
    //dispatch(setGetListLoading(STATUS_API.LOADING));
    dispatch(getListVehicle({agency_id : e.target.value}));
  };

  const handleChangeInput = e => {
    e.preventDefault();
   
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const newValue = e.target.value;
      if (isAdmin) {
        dispatch(getListVehicle({ license_plate: newValue }));
      } else if (isAgency) {
        dispatch(getListVehicle({ license_plate: newValue, agency_id: 1 }));
      }
    }
  };

  return (
    <Paper component="form" className={classes.root}>
      <Box style={{ borderRadius: '4px', marginBottom: '8px' }}>
        {listLicensePlate && (
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            size="small"
            disableClearable
            options={listLicensePlate}
            value={inputValue}
            classes={{ option: classes.option }}
            renderInput={params => (
              <CssTextField
                {...params}
                margin="dense"
                className="input-no-fieldset"
                variant="outlined"
                placeholder="Tìm kiếm phương tiện"
                onChange={e => setInputValue(e.target.value)}
                onKeyDown={keyPress}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: '14px',
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: 'pointer' }}
                    >
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon onClick={e => handleSearchVehicle(e)} />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        )}

        <Box
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '8px'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column ',
              width: '48%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.2em',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={deviceType || ''}
              //onChange={e => setDeviceType(e.target.value)}
              displayEmpty
              disableUnderline
            >
              <MenuItem value="">
                <small> Tất cả thiết bị </small>
              </MenuItem>
              {listDeviceType &&
                listDeviceType.map(deviceType => (
                  <MenuItem value={deviceType.name}>
                    <small>{deviceType.name}</small>
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column ',
              width: '48%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.2em',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={''}
              // value={agencyParams || ''}
              onChange={e => handleChangeAgency(e)}
              displayEmpty
              disableUnderline
            >
              <MenuItem value="">
                <small> Chọn đại lý </small>
              </MenuItem>
              {listAgencies &&
                listAgencies.map(agency => (
                  <MenuItem value={agency.id}>
                    <small>{agency.name}</small>
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 14px',
    boxShadow: 'unset'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: '1 1',
    padding: '0 '
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  titleLotrinh: {
    fontSize: '18px',
    color: '#C62222',
    fontWeight: 700,
    marginBottom: '10px'
  },
  titleTime: {
    fontSize: '18px',
    color: '#0C1132',
    fontWeight: 700,
    marginBottom: '10px'
  },
  formControl: {
    width: 360,
    marginBottom: '10px'
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectDropdown: {
    padding: '5 5 5 5 '
  },
  option: {
    fontSize: "14px"
  }
}));

export default React.memo(ToolBar);
