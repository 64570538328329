import React from 'react';
import {Typography, Box} from '@material-ui/core';
import _orderBy from 'lodash/orderBy';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Tooltip
} from 'react-leaflet';

const LicensePlateTag = ({position, license_plate}) => {
  return (
    <Box>
      <Tooltip
        direction="center"
        permanent
        position={[Number(position.lat), Number(position.lng)]}
        opacity={1}
      >
        <Typography
          style={{
            backgroundColor: '#F5FAFF',
            padding: '1px 4px',
            fontSize: '12px',
            width: 'max-content !important',
            borderRadius: '0.1em',
            color: 'rgb(0, 0, 0)',
            fontWeight: 'bold',
            marginTop: '98px',
            marginLeft: '30px'
          }}
        >
          {license_plate}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default React.memo(LicensePlateTag);
